import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, Divider, Grid, MenuItem, Select, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Typography } from 'lib/components';
import moment from 'moment';
import React from 'react';
import { useEmployeesState, useOrderPhasesState, useOrdersState } from 'store/selectors';
import Order, { OrderSchedule } from '../../../../../models/Order';
import { useAppDispatch } from '../../../../../store';
import { multidragActions } from '../../../../../store/slices/multidrag.slice';
import { ordersActions } from '../../../../../store/slices/orders.slice';
import employeeAsyncActions from '../../../../../store/actions/employees.action';
import ordersAsyncActions from '../../../../../store/actions/orders.action';
import generateStartAt from '../../../../generateStartAt';
import getWorkingHours from '../../../../getWorkingHours';
import isOverlapping from '../../../../isOverlapping';
import NotAcceptedModal from '../../../ErrorModals/NotAcceptedModal';
import SendToSpireConfirmModal from '../../../SendToSpireConfirmModal';

const FormLabel = styled(Typography)({
	fontSize: 12,
	marginBottom: 5,
});

const CustomTextField = styled(TextField)({
	fontSize: 12,
	marginBottom: 5,
	'& > .MuiInputBase-root': {
		borderRadius: 0,
		backgroundColor: '#ffffff',
		fontSize: 12,
		'& > input': {
			backgroundColor: '#ffffff',
			'&:disabled': {
				backgroundColor: '#f1f1f1',
			},
		},
	},
});

const CustomSelect = styled(Select)({
	fontSize: 12,
	borderRadius: 0,
	backgroundColor: '#ffffff',
	'& > .MuiSelect-select': {
		display: 'flex',
		alignItems: 'center',
	},
});

const ColorBox = ({ color }: { color: string }) => {
	return (
		<Box
			width={10}
			minWidth={10}
			height={10}
			mr="5px"
			sx={{ backgroundColor: color }}
			border="1px solid #000000"
		></Box>
	);
};

interface StatusTabPanelProps {
	index: number;
	value: number;
	schedule: OrderSchedule;
	workOrder: Order;
	onClose: () => void;
}

const useStatusTabPanelStyles = makeStyles({
	root: {},
	header: {
		padding: '15px 10px',
		position: 'relative',
		'& > .title': {
			textAlign: 'center',
			color: '#0859a4',
			fontWeight: '600',
		},
		'& > .icon-buttons': {
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			right: 5,
			padding: 0,
			display: 'flex',
			'& > .MuiIconButton-root': {
				height: 20,
				width: 20,
				padding: 0,
				marginRight: 5,
			},
		},
	},
	body: {
		paddingTop: 15,
		padding: '15px 10px',
		'& > .tabs': {
			borderBottom: '0.5px solid #dddfe2',
			minHeight: 0,
			'& .MuiTab-root': {
				fontSize: 14,
				padding: '3px 5px',
				textTransform: 'none',
				minHeight: 0,
				minWidth: 0,
				'&[aria-selected="true"]': {
					color: '#0859a4',
				},
			},
		},
	},
	select: {
		borderRadius: 0,
		fontSize: 12,
		'& li': {
			fontSize: 12,
		},
	},
	menuItem: {
		borderBottom: '0.5px solid #dddfe2',
	},
	footer: {
		display: 'flex',
		padding: '15px 10px',
		'& .warning': {
			marginLeft: 10,
			color: '#fa2e2e',
		},
		'& > .button': {
			margin: '10px 10px 0 0',
			borderRadius: 0,
			color: '#0859a4',
			textTransform: 'none',
			'&:hover': {
				backgroundColor: '#0859a4',
				color: '#ffffff',
			},
		},
	},
});

interface OrderStateusPayload {
	orderStatus: string | null;
	// orderActivity: string;
	// projectManager: string;
	orderNumber: string;
	location: string;
	// resource: string;
	startAtDate: string;
	startAtTime: string;
	endAtDate: string;
	endAtTime: string;
	// comment: string;
}

const StatusTabPanel: React.FC<StatusTabPanelProps> = ({ index, value, onClose, schedule, workOrder, ...other }) => {
	const classes = useStatusTabPanelStyles();

	const dispatch = useAppDispatch();

	const employeesState = useEmployeesState();
	const ordersState = useOrdersState();
	const orderPhases = useOrderPhasesState();

	const [orderItem, setOrderItem] = React.useState<Order | null>(null);
	const [startAtDate, setStartAtDate] = React.useState<string>(
		moment.utc(schedule.startAt.split(',')[0], 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')
	);
	const [startAtTime, setStartAtTime] = React.useState<string>(
		moment.utc(schedule.startAt.split(',')[0], 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
	);
	const [values, setValues] = React.useState<OrderStateusPayload>({
		orderStatus: orderItem?.phase ? orderItem.phase.phaseId : null,
		orderNumber: workOrder.orderNo,
		location: workOrder.address ? workOrder.address.line1 : '',
		startAtDate: moment.utc(schedule.startAt.split(',')[0], 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY'),
		startAtTime: moment.utc(schedule.startAt.split(',')[0], 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
		endAtDate: moment.utc(schedule.endAt).format('MM/DD/YYYY'),
		endAtTime: moment.utc(schedule.endAt).format('HH:mm'),
	});

	const [showSendToSpireConfirmModal, setShowSendToSpireConfirmModal] = React.useState<boolean>(false);
	const [isErrorModalOpen, setIsErrorModalOpen] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

	const handleCloseErrorModal = React.useCallback(() => {
		setIsErrorModalOpen(false);
		setTimeout(() => {
			setErrorMessage(null);
		}, 1000);
	}, []);

	React.useEffect(() => {
		setOrderItem(workOrder);
		if (workOrder) {
			setValues({
				orderStatus: workOrder.phase ? workOrder.phase.phaseId : null,
				orderNumber: workOrder.orderNo,
				location: workOrder.address ? workOrder.address.line1 : '',
				startAtDate: moment.utc(schedule.startAt.split(',')[0], 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY'),
				startAtTime: moment.utc(schedule.startAt.split(',')[0], 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
				endAtDate: moment.utc(schedule.endAt).format('MM/DD/YYYY'),
				endAtTime: moment.utc(schedule.endAt).format('HH:mm'),
			});
			setStartAtDate(moment.utc(schedule.startAt.split(',')[0], 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY'));
			setStartAtTime(moment.utc(schedule.startAt.split(',')[0], 'YYYY-MM-DD HH:mm:ss').format('HH:mm'));
		}
	}, [schedule]);

	const handleChange = React.useCallback((property: string, value: string) => {
		setValues((prev) => {
			return {
				...prev,
				[property]: value,
			};
		});
	}, []);

	const handleSave = React.useCallback(() => {
		const employee = employeesState.list.find((element) => element.id === workOrder.employeeId)!;
		if (
			(startAtDate !== values.startAtDate || startAtTime !== values.startAtTime) &&
			workOrder.employeeId &&
			employee
		) {
			const ids = [workOrder.id];

			const newStartAtTime = moment.utc(values.startAtTime, 'HH:mm');

			const startAtMoment = moment
				.utc(values.startAtDate, 'MM/DD/YYYY')
				.hour(newStartAtTime.hour())
				.minute(newStartAtTime.minute());
			let workingHours = getWorkingHours(employee, startAtMoment.format('ddd'));

			// Adjust working hours to start of worker time.
			if (startAtMoment.hour() < workingHours.from[0]) {
				startAtMoment.hour(workingHours.from[0]).minute(workingHours.from[1]);
			}

			for (const id of ids) {
				const order = ordersState.list.find((element) => element.id === id)!;

				const momentIndexDate = moment.utc(values.startAtDate, 'MM/DD/YYYY');

				const workingHours = getWorkingHours(
					employeesState.list.find((element) => element.id === workOrder.employeeId)!,
					momentIndexDate.format('ddd')
				);

				const workingFrom = momentIndexDate.clone().hour(workingHours.from[0]).minute(workingHours.from[1]);
				const workingTo = momentIndexDate.clone().hour(workingHours.to[0]).minute(workingHours.to[1]);

				const startAtList = generateStartAt(startAtMoment, order, employee);
				const actualStartAtIndex = startAtList.findIndex((element) =>
					element.includes(momentIndexDate.format('YYYY-MM-DD'))
				);

				const startAt = moment.utc(startAtList[actualStartAtIndex]);
				const endAt = moment.utc(startAtList[actualStartAtIndex + 1]);

				if (
					!startAt.isBetween(workingFrom, workingTo, 'minute', '[]') ||
					(!endAt.isBetween(workingFrom, workingTo, 'minute', '[]') && order.estimatedHours <= 1)
				) {
					// TODO: Add message here!
					setErrorMessage('You cannot schedule order here.');
					console.error('Cannot schedule here.');
					return;
				}

				for (let i = 0; i < startAtList.length - 1; i += 2) {
					const tempStartAt = moment.utc(startAtList[i]);
					const tempEndAt = moment.utc(startAtList[i + 1]);

					if (isOverlapping(schedule.id, employee.orders, tempStartAt, tempEndAt)) {
						console.error('Cannot schedule here. Overlapping.');
						setErrorMessage("You cannot schedule order here, because it's overlapping with another one.");
						return;
					}
				}
			}

			dispatch(
				employeeAsyncActions.assignOrder({
					employeeId: workOrder.employeeId,
					ids,
					startAt: moment
						.utc(values.startAtDate, 'MM/DD/YYYY')
						.hour(newStartAtTime.hour())
						.minute(newStartAtTime.minute())
						.format(),
				})
			);
			dispatch(ordersActions.scheduleByIds(ids));
			dispatch(multidragActions.resetSelectedOrders());
		}

		dispatch(
			ordersAsyncActions.update({
				id: workOrder.id,
				phaseId: values.orderStatus,
				orderNo: values.orderNumber,
				location: values.location,
			})
		);

		if (onClose) {
			onClose();
		}
	}, [dispatch, values, startAtDate, startAtTime]);

	React.useEffect(() => {
		if (errorMessage !== null && !isErrorModalOpen) {
			setIsErrorModalOpen(true);
		}
	}, [errorMessage]);

	return (
		<>
			<SendToSpireConfirmModal
				orderId={workOrder.id}
				onClose={() => setShowSendToSpireConfirmModal(false)}
				open={showSendToSpireConfirmModal}
			/>
			<NotAcceptedModal message={errorMessage} onClose={handleCloseErrorModal} open={isErrorModalOpen} />
			<Box className={classes.root} hidden={value !== index} {...other} p="15px 10px" maxWidth="sm" mx="auto">
				<form>
					<Grid container spacing={1} marginBottom="10px">
						<Grid item xs={12} md={2} display="flex" alignItems="center">
							<FormLabel variant="p">Order Phase:</FormLabel>
						</Grid>
						<Grid item xs={12} md={10}>
							<CustomSelect
								className="form-input"
								size="small"
								name="orderStatus"
								value={values.orderStatus}
								fullWidth
								sx={{ width: '100%' }}
								MenuProps={{
									classes: { paper: classes.select },
								}}
							>
								{orderPhases.list
									.filter((element) => element.phaseType === 'SORD')
									.map((item) => (
										<MenuItem
											key={item.id}
											value={item.phaseId}
											component={Button}
											className={classes.menuItem}
											fullWidth
											onClick={() => {
												handleChange('orderStatus', item.phaseId);
											}}
										>
											<ColorBox color={item.color} />
											{item.description}
										</MenuItem>
									))}
							</CustomSelect>
						</Grid>

						{/* <Grid item xs={12} md={4}>
						<FormLabel variant="p" className="form-label">
							Order Activity
						</FormLabel>
						<CustomSelect
							className="form-input"
							value={values.orderActivity}
							onChange={handleSelectChange}
							size="small"
							fullWidth
							MenuProps={{
								classes: { paper: classes.select },
							}}
						>
							<MenuItem value="1">Activity Order</MenuItem>
						</CustomSelect>
					</Grid> */}
						{/* <Grid item xs={12} md={4}>
						<FormControl>
							<FormLabel variant="p" className="form-label">
								Project Manager
							</FormLabel>
							<CustomSelect
								value={values.resource}
								onChange={handleSelectChange}
								name="projectManager"
								className="form-input"
								size="small"
								sx={{ minWidth: '190px' }}
								fullWidth
								MenuProps={{
									classes: { paper: classes.select },
								}}
							>
								<MenuItem value="Bryan Edwards">Level 5 Tech</MenuItem>
							</CustomSelect>
						</FormControl>
					</Grid> */}
					</Grid>
					<Divider light />
					<Grid container spacing={1} marginTop="10px">
						<Grid item xs={12} md={2} display="flex" alignItems="center">
							<FormLabel variant="p">Order No:</FormLabel>
						</Grid>
						<Grid item xs={12} md={10}>
							<CustomTextField
								value={values.orderNumber || orderItem?.orderNo}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
								name="orderNumber"
								fullWidth
								size="small"
							/>
						</Grid>
						<Grid item xs={12} md={2} display="flex" alignItems="center">
							<FormLabel variant="p">Location:</FormLabel>
						</Grid>
						<Grid item xs={12} md={10}>
							<CustomTextField
								value={values.location || orderItem?.customer?.name}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
								fullWidth
								name="location"
								size="small"
							/>
						</Grid>
						{/* <Grid item xs={12} md={2} display="flex" alignItems="center">
						<FormLabel variant="p">Resource:</FormLabel>
					</Grid> */}
						{/* <Grid item xs={12} md={3}>
						<CustomTextField
							value={values.resource}
							onChange={handleChange}
							name="resources"
							fullWidth
							size="small"
						/>
					 </Grid> */}
					</Grid>
					<Grid item xs={12} md={2} display="flex" alignItems="center">
						<FormLabel variant="p">Start Time:</FormLabel>
					</Grid>
					<Grid item xs={12} md={12}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<Grid container spacing={1}>
								<Grid item xs={12} md={7}>
									<CustomTextField
										value={values.startAtDate}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
										fullWidth
										type="text"
										size="small"
										name="startAtDate"
									/>
								</Grid>
								<Grid item xs={12} md={5}>
									<CustomTextField
										value={values.startAtTime}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
										type="text"
										size="small"
										fullWidth
										name="startAtTime"
									/>
								</Grid>
							</Grid>
						</LocalizationProvider>
					</Grid>
					<Grid item xs={12} md={2} display="flex" alignItems="center">
						<FormLabel variant="p">End Time:</FormLabel>
					</Grid>
					<Grid item xs={12} md={12}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<Grid container spacing={1}>
								<Grid item xs={12} md={7}>
									<CustomTextField
										disabled
										fullWidth
										type="text"
										size="small"
										name="endAtDate"
										value={values.endAtDate}
									/>
								</Grid>
								<Grid item xs={12} md={5}>
									<CustomTextField
										disabled
										value={values.endAtTime}
										type="text"
										size="small"
										fullWidth
										name="endAtTime"
									/>
								</Grid>
							</Grid>
						</LocalizationProvider>
					</Grid>
					{/* <Grid item xs={12}>
						<FormLabel variant="p">Comment:</FormLabel>
					</Grid>
					<Grid item xs={12}>
						<CustomTextField
							value={values.comment}
							onChange={handleChange}
							type="time"
							size="small"
							fullWidth
							name="comment"
							multiline
							rows={5}
						/>
					</Grid>
				</Grid> */}
					<Box className={classes.footer}>
						<Button onClick={handleSave} className="button" variant="outlined">
							Save and Close
						</Button>
						<Button className="button" variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<Box flex={1} />
						<Button
							style={{ marginRight: 0 }}
							className="button"
							variant="outlined"
							onClick={() => setShowSendToSpireConfirmModal(true)}
						>
							Send to Spire
						</Button>
					</Box>
				</form>
			</Box>
		</>
	);
};

export default StatusTabPanel;
