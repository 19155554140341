import { createSlice } from '@reduxjs/toolkit';
import Company from 'models/Company';
import AuthenticationResponse from 'network/responses/AuthenticationResponse';
import authenticationAsyncActions from '../actions/authentication.action';
import { CPA, SliceState, Timing } from '../types';

const initialState: SliceState<Company> = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'companies',
	initialState,
	reducers: {},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: (state, { payload }: CPA<AuthenticationResponse>) => {
			if (payload.companies) {
				state.list = payload.companies;
			}
			state.updatedAt = Timing.now();
		},
	},
});

export default slice.reducer;
