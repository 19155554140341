import { createSlice } from '@reduxjs/toolkit';
import { PA, UIState } from '../types';

const initialState: UIState = {
	pulseOrderId: undefined,
	calenderVisible: true,
	leftPanelVisible: true,
};

const slice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		pulseOrderId(state, { payload }: PA<string | undefined>) {
			state.pulseOrderId = payload;
		},
		toggleCalendar(state) {
			state.calenderVisible = !state.calenderVisible;
		},
		toggleFullscreenMode(state) {
			state.calenderVisible = !state.calenderVisible;
			state.leftPanelVisible = !state.leftPanelVisible;
		},
	},
});

export const uiActions = slice.actions;

export default slice.reducer;
