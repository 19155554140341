import { createSlice } from '@reduxjs/toolkit';
import { getHighlightedDates } from 'lib/utils/calendar.utils';
import moment from 'moment';
import { CalendarState, PA } from 'store/types';
import authenticationAsyncActions from '../actions/authentication.action';

const initialState: CalendarState = {
	current: moment().format('YYYY-MM-DD'),
	isDragging: false,
	startDate: moment().format('YYYY-MM-DD'),
	endDate: moment().format('YYYY-MM-DD'),
	highlightedDates: [moment().format('YYYY-MM-DD')],
};

const calenderSlice = createSlice({
	name: 'calendarSlice',
	initialState,
	reducers: {
		setCurrent: (state, { payload }: PA<string>) => {
			state.current = payload;
		},
		setIsDragging: (state, { payload }: PA<boolean>) => {
			state.isDragging = payload;
		},
		setStartDate: (state, { payload }: PA<string>) => {
			state.startDate = payload;
		},
		setEndDate: (state, { payload }: PA<string>) => {
			state.endDate = payload;
		},
		setHighlightedDates: (state, { payload }: PA<{ startDate: string; endDate: string }>) => {
			state.highlightedDates = getHighlightedDates(payload.startDate, payload.endDate);
		},
	},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: (state) => {
			state.current = moment().format('YYYY-MM-DD');
			state.startDate = moment().format('YYYY-MM-DD');
			state.endDate = moment().format('YYYY-MM-DD');
			state.highlightedDates = [moment().format('YYYY-MM-DD')];
		},
	},
});

export const calendarActions = calenderSlice.actions;

export default calenderSlice.reducer;
