import { createSlice } from '@reduxjs/toolkit';
import ErrorResponse from 'network/responses/ErrorResponse';
import { AUsersState, CPA, Timing } from 'store/types';
import AUser from '../../models/Admin/AUser';
import aUsersAsyncActions from '../actions/aUsers.action';
import authenticationAsyncActions from '../actions/authentication.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: AUsersState = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'aUsers',
	initialState,
	reducers: {},
	extraReducers: {
		[aUsersAsyncActions.index.fulfilled.type]: (state, action: CPA<AUser[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[aUsersAsyncActions.store.fulfilled.type]: (state, action: CPA<AUser>) => {
			state.list.push(action.payload);
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[aUsersAsyncActions.update.fulfilled.type]: (state, action: CPA<AUser>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[aUsersAsyncActions.block.fulfilled.type]: (state, action: CPA<AUser>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[aUsersAsyncActions.destroy.fulfilled.type]: (state, action: CPA<Pick<AUser, 'id'>>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[aUsersAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aUsersAsyncActions.store.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aUsersAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aUsersAsyncActions.block.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aUsersAsyncActions.destroy.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
	},
});

export default slice.reducer;
