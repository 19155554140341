import {
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useASyncUsersState, useRequestState } from 'store/selectors';
import ASyncUser from '../../../models/Admin/ASyncUser';
import Company from '../../../models/Company';
import { useAppDispatch } from '../../../store';
import aSyncUsersAsyncActions from '../../../store/actions/aSyncUsers.action';
import RequestManager from '../../../store/request-manager';
import { aServersActions } from '../../../store/slices/aServers.slice';
import CreateSyncUserModal from './CreateSyncUserModal';
import { LS } from '@cyboticx/common';
import { AServersState } from 'store/types';
import API from '../../../lib/utils/API';

const SyncUsersTab = () => {
	const dispatch = useAppDispatch();

	const [aServersState, setAServersState] = useState<AServersState>({
		list: [],
		updatedAt: new Date().getMilliseconds(),
	});

	const users = useASyncUsersState();
	const requestState = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(requestState.updatedAt);

	const [openModal, setOpenModal] = React.useState<boolean>(false);
	const [user, setUser] = React.useState<ASyncUser | null>(null);
	const [isLoading, setIsLoading] = React.useState<string[]>([]);
	const [isLoadingStopArr, setIsLoadingStopArr] = React.useState<string[]>([]);
	const [syncStatusUpdatedAt, setSyncStatusUpdatedAt] = React.useState<number>(0);

	useEffect(() => {
		getAServerState();
	}, [requestUpdatedAt, requestState.updatedAt, syncStatusUpdatedAt]);

	async function getAServerState() {
		try {
			const options = {
				headers: {
					Authorization: `Bearer ${LS.readAccessToken()}`,
				},
			};
			const res = await fetch(`${API.urlPath}/admin/servers`, options);
			const data = await res.json();

			setAServersState({
				list: data,
				updatedAt: new Date().getMilliseconds(),
			});
		} catch (error) {
			toast.error('Something went wrong');
		}
	}

	async function stopSyncApi(serverId: string) {
		try {
			const server = aServersState.list.find((element) => element.id === serverId);

			if (!server) {
				return;
			}

			// const filteredCompanies = server.companies.filter((company)=>company.synchronizationLock)

			const companyIds = server.companies.map((company) => company.id);

			// if(!companyIds.length) return

			const options = {
				method: 'post',
				headers: {
					Authorization: `Bearer ${LS.readAccessToken()}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					companyIds,
				}),
			};

			const res = await fetch(`${API.urlPath}/admin/sync-users/sync-stop`, options);
			const data = await res.json();

			if (data.code !== 200) {
				toast.error(data?.message ?? 'Something went wrong while stopping sync');
				return;
			}

			const updatedList = aServersState.list.map((prevServer) =>
				prevServer.id === serverId ? { ...prevServer } : prevServer
			);

			setAServersState((prevAServersState) => ({
				...prevAServersState,
				list: updatedList,
				updatedAt: new Date().getMilliseconds(),
			}));

			toast.success(data?.message);
			setSyncStatusUpdatedAt((prev) => prev + 1);
		} catch (error) {
			toast.error('Something went wrong while stopping sync');
		}
	}

	const closeDialog = React.useCallback(() => {
		setOpenModal(false);
	}, []);
	const openDialog = React.useCallback(() => {
		setOpenModal(true);
	}, []);

	const getServer = React.useCallback(
		(id: string) => {
			const server = aServersState.list.find((element) => element.id === id);
			if (server) {
				return server.name;
			}

			return '';
		},
		[aServersState.list]
	);

	const synchronizationInProgress = React.useCallback(
		(id: string, stopButton: boolean = false) => {
			const server = aServersState.list.find((element) => element.id === id);
			if (server) {
				let serverIsLoading = isLoading.includes(id);
				if (stopButton) {
					serverIsLoading = isLoadingStopArr.includes(id);
				}
				if (serverIsLoading) {
					return true;
				}
				return server.companies.some((element) => element.synchronizationLock);
			}

			return false;
		},
		[aServersState.list, isLoading]
	);

	const getCompanies = React.useCallback(
		(user: ASyncUser, id: string) => {
			const server = aServersState.list.find((element) => element.id === id);
			if (server) {
				const companyIds = [...(user.companyIds ? user.companyIds : [])].map((element) =>
					parseInt(element, 10)
				);

				const companies: Company[] = [];
				for (const companyId of companyIds) {
					const company = server.companies.find((element) => parseInt(element.id, 10) === companyId);

					if (company) {
						companies.push(company);
					}
				}

				return companies;
			}

			return [];
		},
		[aServersState.list]
	);

	function onSyncApiResponse(requestData: any) {
		const positiveRes = [304, 200];
		if (positiveRes.includes(requestData.status)) {
			toast.error(requestData.errors[0].msg);
		}
		const newIsLoading = [...isLoading];
		const newIsLoadingStop = [...isLoadingStopArr];

		const findIndex = newIsLoading.findIndex((element) => element === requestData.payload.payload.serverId);
		if (findIndex !== -1) {
			newIsLoading.splice(findIndex, 1);
			newIsLoadingStop.splice(findIndex, 1);
		}

		setIsLoading(newIsLoading);
		setIsLoadingStopArr(newIsLoadingStop);
	}

	async function onSucessCheckPermission(userId: string) {
		const user = users.list.find((element) => parseInt(element.id, 10) === parseInt(userId, 10));

		if (user) {
			const companies = getCompanies(user, user.serverId);
			if (companies.length === 0) {
				setIsLoading((prev) => prev.filter((id) => id !== user.serverId));

				toast.error('Please select at least one company to synchronize.');
			} else {
				// setIsLoading((prev) => [user.serverId, ...prev]);
				setIsLoadingStopArr((prev) => [user.serverId, ...prev]);
				// dispatch(aSyncUsersAsyncActions.sync({ id: user.id }));
				toast.success('Synchronization process started.');
				const res = await syncApi(user.id);
				if (res) {
					onSyncApiResponse(res);
				}

				dispatch(
					aServersActions.lockCompanies({
						serverId: user.serverId,
						companyIds: companies.map((element) => element.id),
					})
				);
			}
		}
	}

	function onFailedCheckPermission(serverId: string) {
		const newIsLoading = [...isLoading];
		const findIndex = newIsLoading.findIndex((element) => element === serverId);

		if (findIndex !== -1) {
			newIsLoading.splice(findIndex, 1);
		}

		setIsLoading(newIsLoading);
	}

	return (
		<>
			<CreateSyncUserModal user={user} onClose={closeDialog} open={openModal} />

			<Box>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="Users table">
						<TableHead>
							<TableRow>
								<TableCell sx={{ color: '#0859a4' }} component="h6">
									SERVER
								</TableCell>
								<TableCell sx={{ color: '#0859a4' }} component="h6" color="primary" align="left">
									USERNAME
								</TableCell>
								<TableCell
									sx={{ color: '#0859a4', paddingLeft: 3, paddingRight: 3 }}
									component="h6"
									align="right"
								>
									ACTION
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users.list.map((user, index) => (
								<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell sx={{ fontWeight: 'bold' }}>{getServer(user.serverId)}</TableCell>
									<TableCell sx={{ fontWeight: 'bold' }}>{user.username}</TableCell>
									<TableCell sx={{ width: 450 }} align="right">
										<Button
											style={{ width: 120 }}
											disabled={synchronizationInProgress(user.serverId)}
											onClick={async () => {
												toast.success('Retrieving permissions...', {
													icon: 'ℹ️',
												});
												setIsLoading((prev) => [user.serverId, ...prev]);
												const data = await checkPermissionsApi(user.id);
												const positiveRes = [304, 200];
												if (!data?.userId) {
													setIsLoading((prev) => prev.filter((id) => id !== user.serverId));
													toast.error(data.errors[0].msg);
													onFailedCheckPermission(user.serverId);
												} else {
													await onSucessCheckPermission(user.id);
												}
											}}
										>
											{synchronizationInProgress(user.serverId) && isLoading ? (
												<CircularProgress size={18} />
											) : (
												'Synchronize'
											)}
										</Button>
										{synchronizationInProgress(user.serverId, true) && (
											<Button
												onClick={async () => {
													setIsLoading((prev) => prev.filter((id) => id !== user.serverId));
													await stopSyncApi(user.serverId);
												}}
											>
												{'STOP'}
											</Button>
										)}

										<Button onClick={() => dispatch(aSyncUsersAsyncActions.block({ id: user.id }))}>
											{user.isBlocked ? 'UNBLOCK' : 'BLOCK'}
										</Button>
										<Button
											onClick={() => {
												setUser(user);
												openDialog();
											}}
										>
											EDIT
										</Button>
										<Button
											onClick={() => dispatch(aSyncUsersAsyncActions.destroy({ id: user.id }))}
											color="error"
										>
											DELETE
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

async function checkPermissionsApi(userId: string) {
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${LS.readAccessToken()}`,
			},
		};
		const res = await fetch(`${API.urlPath}/admin/sync-users/${userId}/check-permissions`, options);
		const data = await res.json();
		return data;
	} catch (error) {
		return null;
	}
}

async function syncApi(userId: string) {
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${LS.readAccessToken()}`,
			},
		};
		const res = await fetch(`${API.urlPath}/admin/sync-users/${userId}/sync`, options);
		const data = await res.json();
		return data;
	} catch (error) {
		return null;
	}
}

export default SyncUsersTab;
