import moment from 'moment';
import Employee from '../models/Employee';
import Order from '../models/Order';
import getAvailableMinutes from './getAvailableMinutes';
import getWorkingHours from './getWorkingHours';

const generateStartAt = (startAtMoment: moment.Moment, order: Order, employee: Employee): string[] => {
	const startAtStrings = [];

	let workingHours = getWorkingHours(employee, startAtMoment.format('ddd'));

	let estimatedMinutes = order.estimatedHours * 60;
	let availableMinutes = getAvailableMinutes(employee, startAtMoment.format('ddd'), startAtMoment.format('HH:mm'));

	// Prevent from infinite loop if Employee does not have any working hours.
	let addedDays = 0;
	while (availableMinutes === 0) {
		if (addedDays > 7) {
			break;
		}

		startAtMoment.add(1, 'day');

		workingHours = getWorkingHours(employee, startAtMoment.format('ddd'));

		startAtMoment.hour(workingHours.from[0]).minute(workingHours.from[1]);

		availableMinutes = getAvailableMinutes(employee, startAtMoment.format('ddd'), startAtMoment.format('HH:mm'));

		addedDays++;
	}

	// No working days available.
	if (addedDays > 7) {
		return [];
	}

	if (estimatedMinutes > availableMinutes) {
		while (estimatedMinutes > 0) {
			// Skip free days.
			if (availableMinutes === 0) {
				startAtMoment.add(1, 'day');

				const workingHours = getWorkingHours(employee, startAtMoment.format('ddd'));

				startAtMoment.hour(workingHours.from[0]).minute(workingHours.from[1]);

				availableMinutes = getAvailableMinutes(
					employee,
					startAtMoment.format('ddd'),
					startAtMoment.format('HH:mm')
				);

				continue;
			}

			startAtStrings.push(startAtMoment.format('YYYY-MM-DD HH:mm:ss'));
			if (availableMinutes - estimatedMinutes < 0) {
				startAtMoment.add(availableMinutes, 'minutes');
				estimatedMinutes -= availableMinutes;
			} else {
				startAtMoment.add(estimatedMinutes, 'minutes');
				estimatedMinutes -= estimatedMinutes;
			}
			startAtStrings.push(startAtMoment.format('YYYY-MM-DD HH:mm:ss'));
			if (estimatedMinutes <= 0) {
				break;
			}

			startAtMoment.add(1, 'day');

			const workingHours = getWorkingHours(employee, startAtMoment.format('ddd'));

			startAtMoment.hour(workingHours.from[0]).minute(workingHours.from[1]);

			availableMinutes = getAvailableMinutes(
				employee,
				startAtMoment.format('ddd'),
				startAtMoment.format('HH:mm')
			);
		}
	} else {
		startAtStrings.push(startAtMoment.format('YYYY-MM-DD HH:mm:ss'));
		startAtMoment.add(estimatedMinutes, 'minutes');
		startAtStrings.push(startAtMoment.format('YYYY-MM-DD HH:mm:ss'));
	}

	return startAtStrings;
};

export default generateStartAt;
