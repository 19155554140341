import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../lib/utils/API';
import SignInRequest from 'network/requests/SignInRequest';
import AuthenticationResponse from 'network/responses/AuthenticationResponse';
import OkResponse from 'network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const signIn = createAsyncThunk('authentication/sign-in', async (payload: SignInRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(signIn.typePrefix));
	try {
		const response = await API.post<SignInRequest, AuthenticationResponse>('/auth/sign-in', payload);

		thunkApi.dispatch(requestActions.beforeFulfilled(signIn.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(signIn.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const signOut = createAsyncThunk('authentication/sign-out', async (_, thunkApi) => {
	thunkApi.dispatch(requestActions.started(signOut.typePrefix));
	try {
		const response = await API.get<any, OkResponse>('/auth/sign-out');

		thunkApi.dispatch(requestActions.beforeFulfilled(signOut.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(signOut.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: {}, error });
	}
});

const authenticationAsyncActions = {
	signIn,
	signOut,
};

export default authenticationAsyncActions;
