import icActiveTech from './images/ic-active-tech.svg';
import icAllTech from './images/ic-all-tech.svg';
import icAvailableTech from './images/ic-available-tech.svg';
import icChecked from './images/ic-checked.svg';
import icEmail from './images/ic-email.svg';
import icEstimatedTime from './images/ic-estimated-time.svg';
import icEventNote from './images/ic-event-note.svg';
import icFavicon from './images/ic-favicon.svg';
import icFilter from './images/ic-filter.svg';
import icHome from './images/ic-home.svg';
import icLogo from './images/ic-logo.svg';
import icPhone from './images/ic-phone.svg';
import icRefresh from './images/ic-refresh.svg';
import icScheduleText from './images/ic-schedule-text.svg';
import icSchedule from './images/ic-schedule.svg';
import icSort from './images/ic-sort.svg';
import icSpire from './images/ic-spire.svg';
import icWorkOutline from './images/ic-work-outline.svg';
import icAddress from './images/ic_address.svg';
import icArrowFoward from './images/ic_arrow_foward.svg';
import icArrowLeft from './images/ic_arrow_left.svg';
import icArrowRight from './images/ic_arrow_right.svg';
import icCapUp from './images/ic_cap_up.svg';
import icClock from './images/ic_clock.svg';
import icCross from './images/ic_cross.svg';
import icDialogClose from './images/ic_dialog_close.svg';
import icDialogConfirm from './images/ic_dialog_confirm.svg';
import icDialogEdit from './images/ic_dialog_edit.svg';
import icEdit from './images/ic_edit.svg';
import icErrorBadge from './images/ic_error_badge.svg';
import icExpand from './images/ic_expand.svg';
import icFullscreen from './images/ic_fullscreen.svg';
import icHelmet from './images/ic_helmet.svg';
import icInfo from './images/ic_info.svg';
import icNext from './images/ic_next.svg';
import icOrangeArrowBadge from './images/ic_orange_arrow_badge.svg';
import icRemove from './images/ic_remove.svg';
import icScrollLeft from './images/ic_scroll_left.svg';
import icScrollRight from './images/ic_scroll_right.svg';
import icSetting from './images/ic_setting.svg';
import icSuccessBadge from './images/ic_success_badge.svg';
import icWarning from './images/ic_warning.svg';
import icWorkOrder from './images/ic_work_order.svg';
import icYellowArrowBadge from './images/ic_yellow_arrow_badge.svg';
import ilMain from './images/il-main.svg';
import ilCsiLogo from './images/il_csi_logo.png';
import ilNotaccepted from './images/il_notaccepted.png';
import ilNotfound from './images/il_notfound.png';
import ilSupportLeft from './images/il_support_left.svg';
import ilSupportRight from './images/il_support_right.svg';
import user from './images/user.svg';

const images = {
	dark: {},
	light: {},
	default: {
		icActiveTech,
		icAllTech,
		icAvailableTech,
		icChecked,
		icEmail,
		icEstimatedTime,
		icEventNote,
		icFavicon,
		icFilter,
		icHome,
		icLogo,
		icPhone,
		icRefresh,
		icScheduleText,
		icSchedule,
		icSort,
		icSpire,
		icWorkOutline,
		icAddress,
		icArrowFoward,
		icArrowLeft,
		icArrowRight,
		icCapUp,
		icClock,
		icCross,
		icDialogClose,
		icDialogConfirm,
		icDialogEdit,
		icEdit,
		icErrorBadge,
		icExpand,
		icFullscreen,
		icHelmet,
		icInfo,
		icNext,
		icOrangeArrowBadge,
		icRemove,
		icScrollLeft,
		icScrollRight,
		icSetting,
		icSuccessBadge,
		icWarning,
		icWorkOrder,
		icYellowArrowBadge,
		ilMain,
		ilCsiLogo,
		ilNotaccepted,
		ilNotfound,
		ilSupportLeft,
		ilSupportRight,
		user,
	},
};

export default images;
