import { LS } from '@cyboticx/common';
import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { LoadingState } from 'lib/utils/enums';
import AuthenticationResponse from 'network/responses/AuthenticationResponse';
import ErrorResponse from 'network/responses/ErrorResponse';
import API from '../../lib/utils/API';
import OkResponse from '../../network/responses/OkResponse';
import authenticationAsyncActions from '../actions/authentication.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';
import { AuthenticationState, CPA } from '../types';

const initialState: AuthenticationState = {
	username: '',
	status: LoadingState.IDLE,
	errorMessage: '',
	isAuthenticated: false,
	isAdministrator: false,
	accessToken: '',
	expiryAt: -1,
};

const fillState = (state: WritableDraft<AuthenticationState>, action: CPA<AuthenticationResponse>) => {
	state.username = action.payload.username;
	state.isAuthenticated = true;
	state.status = LoadingState.SUCCEEDED;
	state.errorMessage = '';
	state.isAdministrator = action.payload.isAdministrator;
	state.accessToken = action.payload.accessToken;
	state.expiryAt = action.payload.expiryAt;

	LS.addAccessToken(action.payload.accessToken);
	API.addAccessToken(action.payload.accessToken);

	postRequest(action);
};

const slice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		restoreAccessToken: (state: WritableDraft<typeof initialState>) => {
			API.addAccessToken(state.accessToken === '' ? undefined : state.accessToken);
		},
		removeAuthState: () => {
			API.removeAccessToken();
			return initialState;
		},
		resetAuthState: (state: WritableDraft<typeof initialState>) => {
			if (state.isAuthenticated) {
				return state;
			}

			return initialState;
		},
	},
	extraReducers: {
		[authenticationAsyncActions.signIn.pending.type]: (state) => {
			state.status = LoadingState.PENDING;
		},
		[authenticationAsyncActions.signIn.fulfilled.type]: fillState,
		[authenticationAsyncActions.signIn.rejected.type]: (state, action: CPA<ErrorResponse>) => {
			const message = action.payload.error.list[0].msg;
			state.status = LoadingState.FAILED;
			state.errorMessage = message;

			postErrorRequest(state, action, initialState);
		},
		[authenticationAsyncActions.signOut.fulfilled.type]: (state, action: CPA<OkResponse>) => {
			state.isAuthenticated = false;
			state.accessToken = '';
			state.expiryAt = -1;

			LS.removeAccessToken();
			API.removeAccessToken();

			postRequest(action);
		},
		[authenticationAsyncActions.signOut.rejected.type]: (state, action: CPA<ErrorResponse>) => {
			state.isAuthenticated = false;
			state.accessToken = '';
			state.expiryAt = -1;

			LS.removeAccessToken();
			API.removeAccessToken();

			postErrorRequest(state, action, initialState, false);
		},
	},
});

export const { restoreAccessToken, removeAuthState, resetAuthState } = slice.actions;
export default slice.reducer;
