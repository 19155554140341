import { Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useWindowResize } from 'hooks';
import { Image } from 'lib/components';
import AppHeader from 'lib/components/AppHeader';
import { Breadcrumbs } from 'lib/components/Common';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { IcHome } from 'res/icons';
import { ImageSource } from 'types';
import { useAppDispatch } from '../../store';
import { useAuthenticationState, useCompaniesState } from '../../store/selectors';
import { filtersActions } from '../../store/slices/filters.slice';
import { inventoryItemsActions } from '../../store/slices/inventoryItems.slice';
import { orderPhasesActions } from '../../store/slices/orderPhases.slice';
import { ordersActions } from '../../store/slices/orders.slice';
import orderPhasesAsyncActions from '../../store/actions/orderPhases.action';
import { employeesActions } from '../../store/slices/employees.slice';

const useDashboardStyles = makeStyles(() => ({
	root: {
		minHeight: '100vh',
	},
	breadcrumbs: {
		paddingLeft: '2em',
	},
	board: {
		backgroundColor: '#0859a40f',
		'& > .title': {
			margin: '20px 0',
			fontWeight: 800,
			'& > span': {
				color: '#0a569e',
			},
		},
		'& > .desc': {
			// marginBottom: 20,
			fontWeight: 400,
			lineHeight: 1,
		},
	},
	btn: {
		flexBasis: 280,
		maxWidth: 280,
		height: 280,
		textTransform: 'none',
		color: '#0859a4',
		fontSize: 16,
		fontWeight: 600,
		lineHeight: 1.5,
		borderRadius: 25,
		opacity: 0.2,
		'&.active': {
			opacity: 1,
		},
	},
	support: {
		overflow: 'auto',
		position: 'relative',
		'& > .heading': {
			color: '#0859a4',
			fontWeight: 400,
			marginBottom: 10,
			'& > span': {
				textDecoration: 'line-through',
			},
		},
		'& > .title': {
			fontWeight: 800,
			marginBottom: 10,
		},
		'& > .desc': {
			maxWidth: 350,
			color: '#0859a4',
			fontWeight: 400,
			display: 'inline-block',
		},
		'& > .left-img': {
			position: 'absolute',
			bottom: 0,
			left: 0,
		},
		'& > .right-img': {
			float: 'right',
		},
	},
	contact: {
		'& > .title': {
			color: '#0859a4',
			fontWeight: 700,
		},
		'& .contact-info': {
			paddingLeft: 10,
			color: '#0859a4',
			fontWeight: 600,
			'& > span': {
				color: '#000000',
				fontWeight: 400,
			},
		},
		'& > .question': {
			fontWeight: 800,
			'& > span': {
				color: '#0a569e',
			},
		},
	},
}));

interface UrlParam {
	companyName: string;
}

export const DashboardScreen = () => {
	const dispatch = useAppDispatch();

	const classes = useDashboardStyles();
	const { width } = useWindowResize();
	const { companyName } = useParams<UrlParam>();
	// const [userName, setUsername] = useState();
	// useEffect(())

	const authenticationState = useAuthenticationState();
	const companiesState = useCompaniesState();

	const currentCompany = React.useMemo<string>(() => {
		const company = companiesState.list.find((element) => element.name === companyName);
		if (company && company.description) {
			return company.description;
		}

		return '';
	}, [companyName]);

	React.useEffect(() => {
		dispatch(inventoryItemsActions.clear());
		dispatch(employeesActions.clear());
		dispatch(filtersActions.clear());
		dispatch(orderPhasesActions.clear());
		dispatch(ordersActions.clear());

		dispatch(orderPhasesAsyncActions.index(companyName));
	}, []);

	return (
		<Box className={classes.root}>
			<AppHeader />
			<Breadcrumbs className={classes.breadcrumbs}>
				<Link className="nav-item" to="/">
					<IcHome />
				</Link>
				<Link className="nav-item" to="/selectcompany">
					{currentCompany}
				</Link>
				<Link className="nav-item active" to={`/company/${companyName}/dashboard`}>
					Dashboard
				</Link>
			</Breadcrumbs>
			<Grid height="87vh" className="h-full" container spacing={0}>
				<Grid item xs={12} lg={7} component={Box} className={classes.board} px={['10px', '60px']}>
					<Typography variant="h2" fontSize={[18, 27, 36]} className="title">
						Hello, <span>{authenticationState.username}</span>
					</Typography>
					<Typography variant="body2" fontSize={[12, 16]} className="desc">
						What are you looking to do today?
					</Typography>
					<Box
						display="grid"
						columnGap="2em"
						rowGap="2em"
						marginTop="2em"
						paddingBottom="2em"
						gridTemplateColumns={width < 670 ? 'repeat(1,280px)' : 'repeat(2,280px)'}
					>
						{[
							{ src: 'icWorkOutline', txt: 'Work Order Screen' },
							{ src: 'icSchedule', txt: 'Schedule Board' },
							{ src: 'icEventNote', txt: 'Order Tracking' },
							{ src: 'icSchedule', txt: 'Project Manage Information' },
						].map((item, index) => (
							<Button
								key={index}
								variant="outlined"
								className={`${classes.btn} companies-item` + (index === 1 ? ' active' : '')}
								component={Link}
								to={`/company/${companyName}/scheduleboard`}
							>
								<Box textAlign="center">
									<Image src={item.src as ImageSource} />
									<br />
									{item.txt}
								</Box>
							</Button>
						))}
					</Box>
				</Grid>
				<Grid item xs={12} lg={5}>
					<Box p={['10px', '30px 60px']}>
						<Box className={classes.support}>
							<Typography variant="h4" fontSize={[12, 14]} className="heading">
								<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
								&nbsp;Schedule Pro Support
							</Typography>
							<Typography variant="h3" fontSize={[18, 27, 36]} className="title">
								Contact us for support
							</Typography>
							<Typography variant="body2" fontSize={[12, 14]} className="desc">
								Feel free to contact our support team for any questions you may have about our product.
							</Typography>
							<Image src="ilSupportRight" width="30%" className="right-img" />
							<Image src="ilSupportLeft" width="70%" className="left-img" />
						</Box>
					</Box>
					<Divider light />
					<Box className={classes.contact} p={['10px', '30px 60px']}>
						<Typography variant="h3" fontSize={[16, 22]} className="title">
							Get in touch
						</Typography>
						<Box py={['50px']}>
							<Grid container>
								<Grid item xs={12} md={6}>
									<Box display="flex" alignItems="center">
										<Image src="icPhone" />
										<Typography variant="body2" fontSize={[12, 14]} className="contact-info">
											Phone
											<br />
											<span>+(416) 726-4662</span>
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={12} md={6}>
									<Box display="flex" alignItems="center">
										<Image src="icEmail" />
										<Typography variant="body2" fontSize={[12, 14]} className="contact-info">
											Email
											<br />
											<span style={{ fontSize: '13px' }}>SPPSupport@CSI-International.ca</span>
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Box>
						<Box className="flex justify-between items-center ">
							<Typography variant="body2" fontSize={[16, 22]} className="question" fontWeight="bolder">
								Frequently Asked
								<span className="text-primary "> Questions</span>
							</Typography>
							<IconButton>
								<Image width="70%" src="icArrowFoward" />
							</IconButton>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
