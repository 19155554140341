import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../lib/utils/API';
import Employee from 'models/Employee';
import AssignOrderRequest from 'network/requests/AssignOrderRequest';
import UnassignOrderRequest from '../../network/requests/UnassignOrderRequest';
import UpdateEmployeeRequest from '../../network/requests/UpdateEmployeeRequest';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('employees/index', async (companyName: string, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, Employee[]>(`/employees/${companyName}`);

		thunkApi.dispatch(requestActions.beforeFulfilled(index.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(index.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { companyName }, error });
	}
});

const sync = createAsyncThunk('employees/sync', async (companyName: string, thunkApi) => {
	thunkApi.dispatch(requestActions.started(sync.typePrefix));
	try {
		const response = await API.get<any, Employee[]>(`/employees/${companyName}/sync`);

		thunkApi.dispatch(requestActions.beforeFulfilled(sync.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(sync.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { companyName }, error });
	}
});

const assignOrder = createAsyncThunk('employees/assign-order', async (payload: AssignOrderRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(assignOrder.typePrefix));
	const { employeeId, ...rest } = payload;
	try {
		const response = await API.post<Omit<AssignOrderRequest, 'employeeId'>, Employee>(
			`employees/${employeeId}/assign`,
			rest
		);

		thunkApi.dispatch(requestActions.beforeFulfilled(assignOrder.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(assignOrder.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const unassignOrder = createAsyncThunk(
	'employees/unassign-order',
	async ({ id, ...payload }: UnassignOrderRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(unassignOrder.typePrefix));
		try {
			const response = await API.post<Pick<UnassignOrderRequest, 'orderId'>, Employee>(
				`employees/${id}/unassign`,
				payload
			);

			thunkApi.dispatch(requestActions.beforeFulfilled(unassignOrder.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(unassignOrder.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const update = createAsyncThunk('employees/update', async (payload: UpdateEmployeeRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	const { id, ...rest } = payload;
	try {
		const response = await API.put<Omit<UpdateEmployeeRequest, 'id'>, Employee>(`employees/${id}`, rest);

		thunkApi.dispatch(requestActions.beforeFulfilled(update.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(update.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const employeeAsyncActions = {
	index,
	sync,
	assignOrder,
	unassignOrder,
	update,
};

export default employeeAsyncActions;
