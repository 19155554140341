import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import authenticationAsyncActions from '../actions/authentication.action';
import { PA, SchedulerState } from '../types';

const initialState: SchedulerState = {
	schedulerScrollTo: -1,
	isDragging: false,
	schedulerResolution: {
		mode: '1-hour',
		boxWidth: 120,
		boxCount: 24,
		oneHourWidth: 120,
		oneMinuteWidth: 2,
		intervals: [...Array(24).keys()].map((e) =>
			moment()
				.hour(0)
				.minute(0)
				.add(e * 60, 'minute')
				.format('hh:mm A')
		),
	},
};

const slice = createSlice({
	name: 'scheduler',
	initialState,
	reducers: {
		scrollSchedulerTo: (state, { payload }: PA<number>) => {
			state.schedulerScrollTo = payload;
		},
		setIsDragging: (state, { payload }: PA<boolean>) => {
			state.isDragging = payload;
		},
		setSchedulerResolution: (state, { payload }: PA<SchedulerState['schedulerResolution']>) => {
			state.schedulerResolution = payload;
		},
	},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: (state) => {
			state.schedulerResolution = {
				mode: '1-hour',
				boxWidth: 120,
				boxCount: 24,
				oneHourWidth: 120,
				oneMinuteWidth: 2,
				intervals: [...Array(24).keys()].map((e) =>
					moment()
						.hour(0)
						.minute(0)
						.add(e * 60, 'minute')
						.format('hh:mm A')
				),
			};
		},
	},
});

export const schedulerActions = slice.actions;
export default slice.reducer;
