import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { hexToRGB } from 'lib/utils/coli.util';
import moment from 'moment';
import React from 'react';
import { IcClock } from 'res/icons';
import Employee from '../../../../models/Employee';
import { useCalendarState } from '../../../../store/selectors';
import getAvailability from '../../../getAvailability';

const useUserBoxItemStyles = makeStyles({
	root: {
		borderRadius: 0,
		textTransform: 'none',
		color: '#000000',
		border: '0.5px solid #dddfe2',
		height: 36,
	},
	name: {
		padding: '0 5px',
		minWidth: 110,
		maxWidth: 110,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		fontSize: 10,
		fontWeight: 600,
	},
	time: {
		padding: '0 5px',
		textAlign: 'center',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		fontSize: 10,
	},
	badge: {
		minWidth: 10,
		maxWidth: 10,
	},
});

interface UserBoxItemProps {
	employee: Employee;
	color: string;
	onClick?: () => void;
}

const StyledUserBox = styled(Button)`
	background-color:${(props: { bgcolor?: string }) => hexToRGB(props.bgcolor || '#ffffff', 0.4)};

	:hover{
		background-color:${(props: { bgcolor?: string }) => hexToRGB(props.bgcolor || '#ffffff', 0.2)}
	}

}`;

export const UserBoxItem = ({ onClick = () => {}, ...props }: UserBoxItemProps) => {
	const classes = useUserBoxItemStyles();

	const calendarState = useCalendarState();

	const usedTime = React.useMemo<string>(() => {
		let minutes = 0;
		for (const date of calendarState.highlightedDates) {
			const momentDate = moment.utc(date, 'YYYY-MM-DD');

			const orders = props.employee.orders.filter((element) =>
				element.startAt.includes(momentDate.format('YYYY-MM-DD'))
			);

			for (const order of orders) {
				const dates = order.startAt.split(',');

				const actualDates = dates.filter((element) => element.includes(momentDate.format('YYYY-MM-DD')));
				const startAt = moment.utc(actualDates[0]);
				const endAt = moment.utc(actualDates[1]);

				minutes += Math.abs(endAt.diff(startAt, 'minutes'));
			}
		}

		let hours = 0;
		while (minutes >= 60) {
			hours++;

			minutes -= 60;
		}

		return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
	}, [props.employee, calendarState.highlightedDates]);

	return (
		<StyledUserBox
			bgcolor={props.color}
			// sx={{ backgroundColor: hexToRGB(color || '#ffffff', 0.3) }}
			className={classes.root}
			onClick={onClick}
		>
			<Box display={'flex'} flexDirection={'row'} width={'100%'}>
				{getAvailability(props.employee.udfs)}
				<Typography variant="body2" className={classes.name}>
					{props.employee.name}
				</Typography>
				<IcClock className={classes.badge} />
				<Typography variant="body2" className={classes.time}>
					{usedTime}
				</Typography>
			</Box>
		</StyledUserBox>
	);
};

const UserBox = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: '#ffffff',
	width: 200,
	height: '100%',
	'& > *': {
		minWidth: '100%',
	},
	'&::-webkit-scrollbar': {
		height: 30,
	},
	'&::-webkit-scrollbar-corner, &::-webkit-scrollbar-track': {
		backgroundColor: '#ffffff',
		border: '0.5px solid #dddfe2',
	},
});

export default UserBox;
