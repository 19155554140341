import React from 'react';
import { useDispatch } from 'react-redux';
import { io, Socket } from 'socket.io-client';
import SocketContext from '../../../context/SocketContext';
import { OrderPhase } from '../../../models/Order';
import { useAuthenticationState } from '../../../store/selectors';
import { employeesActions } from '../../../store/slices/employees.slice';
import { ordersActions } from '../../../store/slices/orders.slice';
import AttendanceStatues from '../../../types/AttendanceStatues';

interface Props {
	children: React.ReactNode;
}

const API_HOST = process.env.REACT_APP_API_URL!;

const SocketManager: React.FC<Props> = ({ children }: Props) => {
	const dispatch = useDispatch();

	const [socket, setSocket] = React.useState<Socket | null>(null);

	const authenticationState = useAuthenticationState();

	React.useEffect(() => {
		if (!authenticationState.accessToken || !authenticationState.isAuthenticated) {
			return;
		}

		const newSocket = io(API_HOST, {
			reconnection: true,
			reconnectionAttempts: Infinity,
			reconnectionDelay: 10000,
			transports: ['websocket'],
			query: {
				Authorization: authenticationState.accessToken,
			},
		});

		newSocket.on('connect', () => {
			console.log(`SocketState: Connected to the server at ${API_HOST}`);
		});
		newSocket.on('connect_error', (error: Error) => {
			console.error('SocketState: connect_error', error);
		});
		newSocket.on('disconnect', () => {
			console.log('SocketState: Disconnected from the server.');
		});

		setSocket(newSocket);

		return () => {
			if (socket && socket.connected) {
				socket.disconnect();
				socket.close();
				setSocket(null);
			}
		};
	}, [authenticationState.accessToken, authenticationState.isAuthenticated]);

	React.useEffect(() => {
		if (!socket) {
			return;
		}

		socket.on('attendance:update', (employeeId: string, status: AttendanceStatues) => {
			dispatch(employeesActions.attendanceUpdate({ employeeId, status }));
		});

		socket.on('order-phase:change', (orderId: string, phase: OrderPhase) => {
			dispatch(ordersActions.updatePhase({ orderId, phase }));
		});

		return () => {
			socket.off('attendance:update');
			socket.off('order-phase:change');
		};
	}, [socket]);

	return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

export default SocketManager;
