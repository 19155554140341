// import { connect } from 'react-redux';
import { ArrowDropDown, FilterList } from '@mui/icons-material';
import {
	Box,
	Button,
	ButtonProps,
	CircularProgress,
	IconButton,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useMediaQuery,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled, useTheme } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { makeStyles } from '@mui/styles';
import Order from 'models/Order';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { IcEdit, IcRefresh } from 'res/icons';
import { useAppDispatch } from 'store';
import ordersAsyncActions from 'store/actions/orders.action';
import { useFiltersState, useOrdersState, useSearchState, useUIState } from 'store/selectors';
import { multidragActions } from 'store/slices/multidrag.slice';
import FilterCondition from '../../../../models/FilterCondition';
import FilterField from '../../../../models/FilterField';
import FilterMatchMode from '../../../../models/FilterMatchMode';
import FilterMode from '../../../../models/FilterMode';
import filtersAsyncActions from '../../../../store/actions/filters.action';
import searchAsyncActions from '../../../../store/actions/search.action';
import { searchActions } from '../../../../store/slices/search.slice';
import useSearch from '../../../useSearch';
import WorkOrderItemDialog from '../WorkOrderItemDialog';
import FilterButtonsDropdown from './FilterButtonsDropdown';
import FilterMenu from './FilterMenu';
import WorkOrderItemCell from './WorkOrderItemCell';
import { ordersActions } from 'store/slices/orders.slice';

function Loader() {
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					height: '100%',
					justifyContent: 'center',
					alignItems: 'center',
					paddingTop: 10,
					paddingBottom: 10,
					overflow: 'hidden',
				}}
			>
				<CircularProgress />
			</Box>
		</>
	);
}

function NoItems() {
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					height: '100%',
					justifyContent: 'center',
					alignItems: 'center',
					paddingTop: 10,
					paddingBottom: 10,
					overflow: 'hidden',
				}}
			>
				<h4
					style={{
						color: 'blue',
					}}
				>
					No Orders!
				</h4>
			</Box>
		</>
	);
}

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} TransitionProps={{ unmountOnExit: true }} />
))(() => ({
	minWidth: 200,
	backgroundColor: '#e6eef6',
	// border: `1px solid #dddfe2`,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(() => ({
	padding: 0,
	height: 40,
	justifyContents: 'center',

	'& .MuiAccordionSummary-content': {
		margin: 0,
		padding: 0,
		backgroundColor: '#e6eef6',
		color: '#0859a4',
		justifyContent: 'center',
	},
	'& .expand-icon': {
		transition: 'transform ease .3s',
		'.Mui-expanded &': {
			transform: 'rotate(180deg)',
		},
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
	padding: 0,
	borderTop: '1px solid rgba(0, 0, 0, .125)',
	'& .MuiMenuItem-root': {
		justifyContent: 'space-between',
		'&:not(:last-child)': {
			borderBottom: `1px solid #dddfe2`,
		},
	},
}));

const useWorkOrderStyles = makeStyles({
	root: {
		'& .MuiTableContainer-root': {
			borderRight: '0.5px solid #dddfe2',
			'& table': {
				borderCollapse: 'collapse',
			},
			'& td, & th': {
				border: '0.5px solid #dddfe2',
			},
		},
	},
	iconButton: {
		height: '100%',
		// backgroundColor: '#0859a4',
		flexBasis: 35,
		minWidth: 35,
		borderRadius: 0,
		'& svg': {
			height: 20,
		},
	},
	iconButtonLight: {
		height: '100%',
		backgroundColor: 'transparent',
		flexBasis: 35,
		minWidth: 35,
		borderRadius: 0,
		'& svg': {
			height: 20,
		},
	},
	editButton: {},
});

const CustomTableContainer = styled(TableContainer)`
	overflow-x: scroll;
	backgroundcolor: '#0859a40a';
	::-webkit-scrollbar {
		display: none;
	}
`;
const StyledTableCell = styled(TableCell)({
	padding: 0,
	height: 80,
	'.tab-header &': {
		height: 40,
	},
	'.search-header &': {
		height: 30,
	},
	'&.icon': {
		width: '35px',
		maxWidth: '35px',
	},
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#f5f8fb',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
});

const CustomedInput = styled(
	(props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
		return <input placeholder="Search..." required {...props}></input>;
	}
)(() => ({
	backgroundColor: 'transparent',
	display: 'block',
	width: '100%',
	height: '100%',
	paddingLeft: 10,
	transition: 'padding ease .3s',
}));

const StyledTableRow = styled(TableRow)({
	backgroundColor: '#0859a40a',
});

const TabButton = styled((props: ButtonProps & { index: number; value: number }) => (
	<Button fullWidth {...(props as ButtonProps)}></Button>
))(({ index, value }) => ({
	height: '100%',
	backgroundColor: index === value ? '#0859a4' : '',
	'&:hover': {
		backgroundColor: index === value ? '#0859a4cc' : '',
		// color: index === value ? '#ffffffcc' : '#0859a4cc',
	},
	color: index === value ? '#ffffff' : '#0859a4',
	borderRadius: 0,
	textTransform: 'none',
}));

// const mapDispatchToProps = (dispatch: AppDispatch) => {
// 	return {
// 		setDragData: (value: WorkOrderFormat) => dispatch({ type: 'drag/setDragData', payload: value }),
// 		unsetDragData: () => dispatch({ type: 'drag/unsetDragData' }),
// 	};
// };

// interface WorkOrderProps {
// 	// drag: DragState;
// 	setDragData: (value: WorkOrderFormat) => void;
// 	unsetDragData: () => void;
// }

const filterFields: FilterField[] = [
	{
		name: 'orderNo',
		label: 'Order No',
		type: 'TEXT',
	},
	{
		name: 'serialNo',
		label: 'Serial No',
		type: 'TEXT',
	},
	{
		name: 'orderStatus',
		label: 'Order Status',
		type: 'TEXT',
	},
	{
		name: 'invoiceNo',
		label: 'Invoice No',
		type: 'TEXT',
	},
	{
		name: 'customerNo',
		label: 'Customer No',
		type: 'TEXT',
	},
	{
		name: 'customerName',
		label: 'Customer Name',
		type: 'TEXT',
	},
	{
		name: 'referenceNo',
		label: 'Reference',
		type: 'TEXT',
	},
	{
		name: 'trackingNo',
		label: 'Way bill',
		type: 'TEXT',
	},
	{
		name: 'orderDate',
		label: 'Order Date',
		type: 'TEXT',
	},
	{
		name: 'requiredDate',
		label: 'Required Date',
		type: 'TEXT',
	},
];

const filterModes: FilterMode[] = [
	{ name: 'contains', value: 'contains' },
	{ name: 'does not contain', value: '!contains' },
	{ name: 'is', value: 'eq' },
	{ name: 'is not', value: '!eq' },
	{ name: 'starts with', value: 'starts' },
	{ name: 'is less than', value: 'lt' },
	{ name: 'is less than or equal', value: 'lte' },
	{ name: 'is greater than', value: 'gt' },
	{ name: 'is greater than or equal', value: 'gte' },
	{ name: 'is between', value: 'between' },
	{ name: 'is unset', value: '!set' },
	{ name: 'is set', value: 'set' },
];

export const WorkOrder = () => {
	const classes = useWorkOrderStyles();

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('sm'));

	const dispatch = useAppDispatch();

	const ordersState = useOrdersState();
	const searchState = useSearchState();
	const filtersState = useFiltersState();
	const uiState = useUIState();
	const scrollRef = React.useRef<HTMLDivElement>(null);
	const filterButton = React.useRef(null);
	const filterButtonDropDown = React.useRef(null);

	const [page, setPage] = React.useState<number>(1);
	const [limit] = React.useState<number>(50);
	const [isInitialized, setIsInitialized] = React.useState<boolean>(false);
	const [isSearchAdvanced, setIsSearchAdvanced] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isScheduled, setIsScheduled] = React.useState<boolean>(false);
	const [query, setQuery] = React.useState<string>('');
	const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
	const [filterButtonsOpen, setFilterButtonsOpen] = React.useState<boolean>(false);
	const [selectedWorkOrder, setSelectedOrder] = React.useState<Order | null>(null);
	const [openDialog, setOpenDialog] = React.useState(false);
	const [tab, setTab] = React.useState<number>(0);
	const [expand, setExpand] = React.useState<boolean>(false);
	const [filterId, setFilterId] = React.useState<string | undefined>();
	const [matchMode, setMatchMode] = React.useState<FilterMatchMode>('all');
	const [currentPage, setCurrentPage] = React.useState(1);
	const [conditions, setConditions] = React.useState<FilterCondition[]>([
		{
			field: filterFields[0].name,
			mode: filterModes[0].value,
			type: filterFields[0].type,
			value: '',
			valueTwo: '',
		},
	]);
	const [from, setFrom] = React.useState(0);

	const params = useParams<{
		companyName: string;
	}>();

	const refreshSearch = useCallback(
		function (from: number, isScheduled: boolean) {;
			setIsLoading(true);

			if (isSearchAdvanced) {
				dispatch(
					searchAsyncActions.advanced({
						companyName: params.companyName,
						matchMode,
						conditions,
						from,
						isScheduled,
					})
				).then(() => setIsLoading(false));
			} else {
				dispatch(
					searchAsyncActions.search({
						companyName: params.companyName,
						query,
						isScheduled,
						from,
					})
				).then(() => setIsLoading(false));
			}
		},
		[query, from, matchMode, conditions, params, setIsLoading, isSearchAdvanced]
	);

	useEffect(() => {
		console.log('ordersState.refresh : ', ordersState.refresh);
		refreshSearch(from, isScheduled);
	}, [ordersState.refresh]);

	useSearch(
		query,
		() => dispatch(searchActions.clearFilters()),
		() => {
			dispatch(ordersActions.setIsLoading(true));
			dispatch(searchAsyncActions.search({ companyName: params.companyName, query, isScheduled, from }));
		}
	);

	const scheduledOrders = React.useMemo<Order[]>(() => {
		let list = ordersState.list.filter((element) => element.isScheduled);
		if (searchState.applyFilters) {
			list = list.filter((element) => searchState.list.documents?.includes(element.id));
		}
		return list;
	}, [ordersState.list, ordersState.updatedAt, searchState.list, searchState.updatedAt]);

	const unScheduledOrders = React.useMemo<Order[]>(() => {
		let list = ordersState.list.filter((element) => !element.isScheduled);
		if (searchState.applyFilters) {
			list = list.filter((element) => searchState.list.documents?.includes(element.id));
		}

		return list;
	}, [ordersState.list, ordersState.updatedAt, searchState.list, searchState.updatedAt]);

	const handleFilterClick = () => {
		setFilterOpen(true);
	};

	const handleFilterClose = () => {
		setFilterOpen(false);
	};

	const handleFilterButtonsClick = () => {
		setFilterButtonsOpen(true);
	};

	const handleFilterButtonsClose = () => {
		setFilterButtonsOpen(false);
	};

	const closeDialog = () => {
		setOpenDialog(false);
	};
	React.useEffect(() => {
		if (!searchState.list.documents?.length) {
			console.log(dispatch(ordersActions.setIsLoading(false)));
			dispatch(ordersActions.setIsLoading(false));
		}
		if (searchState.list.documents?.length > 0 && searchState.applyFilters) {
			dispatch(ordersAsyncActions.indexByIds({ ids: searchState.list.documents }));
		}
	}, [searchState.list, searchState.updatedAt]);

	React.useEffect(() => {
		dispatch(ordersAsyncActions.index({ name: params.companyName, page, limit }));
	}, [page, limit]);

	React.useEffect(() => {
		dispatch(ordersAsyncActions.indexScheduled({ name: params.companyName }));
		dispatch(filtersAsyncActions.index(params.companyName));
		dispatch(multidragActions.resetSelectedOrders());
	}, []);

	React.useEffect(() => {
		setIsLoading(searchState.isLoading || ordersState.isLoading);
		// setIsLoading(true)
	}, [searchState.isLoading, ordersState.isLoading]);

	React.useEffect(() => {
		if (filterId) {
			const findIndex = filtersState.list.findIndex((element) => element.id === filterId);
			if (findIndex !== -1) {
				setMatchMode(filtersState.list[findIndex].mode);
				setConditions(filtersState.list[findIndex].conditions);

				dispatch(
					searchAsyncActions.advanced({
						companyName: params.companyName,
						matchMode: filtersState.list[findIndex].mode,
						conditions: filtersState.list[findIndex].conditions,
						from,
						isScheduled,
					})
				);
			}
		} else {
			setMatchMode('all');
			setConditions([
				{
					field: filterFields[0].name,
					mode: filterModes[0].value,
					type: filterFields[0].type,
					value: '',
					valueTwo: '',
				},
			]);

			dispatch(searchActions.clearFilters());
		}
	}, [filterId]);

	React.useEffect(() => {
		console.log('filtersState.list : ', filtersState.list);
		if (isInitialized || filterId) {
			return;
		}

		const findFilter = filtersState.list.find((element) => element.isDefault);
		if (findFilter) {
			setFilterId(findFilter.id);
			setMatchMode(findFilter.mode);
			setConditions(findFilter.conditions);

			dispatch(
				searchAsyncActions.advanced({
					companyName: params.companyName,
					matchMode: findFilter.mode,
					conditions: findFilter.conditions,
					from,
					isScheduled,
				})
			);
		}

		if (filtersState.isInitialized) {
			setIsInitialized(true);
		}
	}, [filtersState.list, filtersState.updatedAt]);

	React.useEffect(() => {
		if (ordersState.isPending) {
			return;
		}

		if (scrollRef.current) {
			const onScroll = (e: any) => {
				const element = e.target;
				if (element && element.scrollHeight - element.scrollTop <= element.clientHeight + 150) {
					if (ordersState.hasMorePages) {
						setPage((prev) => prev + 1);
					}
				}

				if (element && element.scrollHeight - element.scrollTop <= element.clientHeight) {
					if (scrollRef.current) {
						scrollRef.current.scrollTo({
							top: scrollRef.current.scrollHeight,
							behavior: 'smooth',
						});
					}
				}
			};

			scrollRef.current.addEventListener('scroll', onScroll);

			return () => {
				if (scrollRef.current) {
					scrollRef.current.removeEventListener('scroll', onScroll);
				}
			};
		}
	}, [scrollRef.current, ordersState.hasMorePages, ordersState.isPending]);

	React.useEffect(() => {
		if (selectedWorkOrder) {
			const find = ordersState.list.find((element) => element.id === selectedWorkOrder.id);
			if (find) {
				setSelectedOrder(find);
			}
		}
	}, [ordersState.list]);

	function handleNextAndPreviousClick(fromValue: number, prevPageValue: number) {
		setFrom(fromValue);
		setIsLoading(true);
		setCurrentPage((prevPage) => prevPage + prevPageValue);
		refreshSearch(from, isScheduled);
	}

	const handleNextClick = () => {
		handleNextAndPreviousClick(from + 25, 1);
	};

	const handlePrevClick = () => {
		handleNextAndPreviousClick(from - 25, -1);
	};

	return (
		<Accordion className={classes.root} expanded={expand || matches}>
			<WorkOrderItemDialog onClose={closeDialog} open={openDialog} workOrder={selectedWorkOrder as Order} />
			{!matches && (
				<AccordionSummary
					onClick={() => {
						if (expand) setExpand(false);
						else setExpand(true);
					}}
				>
					<Typography fontWeight={600}>Work Orders</Typography>
				</AccordionSummary>
			)}
			<AccordionDetails>
				<Table sx={{ width: '100%' }}>
					<TableHead>
						<StyledTableRow className="tab-header" sx={{ height: 40 }}>
							<StyledTableCell>
								<TabButton
									style={{ height: 40 }}
									index={0}
									value={tab}
									onClick={() => {
										dispatch(multidragActions.resetSelectedOrders());
										setIsScheduled(false);
										setFrom(0);
										setCurrentPage(1);
										setTab(0);
										refreshSearch(0, false);
									}}
								>
									Open
								</TabButton>
							</StyledTableCell>
							<StyledTableCell>
								<TabButton
									style={{ height: 40 }}
									index={1}
									value={tab}
									onClick={() => {
										dispatch(multidragActions.resetSelectedOrders());
										setIsScheduled(true);
										setFrom(0);
										setCurrentPage(1);
										setTab(1);
										refreshSearch(0, true);
									}}
								>
									Scheduled
								</TabButton>
							</StyledTableCell>
							<StyledTableCell className="icon">
								<Button
									style={{ height: 40 }}
									fullWidth
									className={classes.iconButtonLight}
									onClick={() => {
										refreshSearch(from, isScheduled);
									}}
								>
									<IcRefresh />
								</Button>
							</StyledTableCell>
						</StyledTableRow>
						<StyledTableRow sx={{ height: 42 }} className="search-header">
							<StyledTableCell colSpan={2}>
								<Box display="flex" height={42}>
									<Box flex={2} overflow="hidden">
										<CustomedInput
											value={query}
											onChange={(e) => {
												dispatch(multidragActions.resetSelectedOrders());
												setIsSearchAdvanced(false);
												setFrom(0);
												setCurrentPage(1);
												setQuery(e.target.value);
											}}
										/>
									</Box>
									<Box height={42} flex={1} overflow="hidden">
										<Button
											variant="contained"
											color="primary"
											fullWidth
											ref={filterButton}
											className={classes.iconButton}
											sx={{ borderLeft: '0.5px solid #dddfe2' }}
											onClick={handleFilterClick}
										>
											<FilterList sx={{ color: '#ffffff' }} />
											<Typography sx={{ color: '#ffffff', fontSize: '.8rem' }}>Filter</Typography>
										</Button>
									</Box>

									<FilterMenu
										setFrom={setFrom}
										setCurrentPage={setCurrentPage}
										refreshSearch={refreshSearch}
										from={from}
										setIsSearchAdvanced={setIsSearchAdvanced}
										filterFields={filterFields}
										filterModes={filterModes}
										matchMode={matchMode}
										conditions={conditions}
										setFilterOpen={setFilterOpen}
										setMatchMode={setMatchMode}
										setConditions={setConditions}
										id="lock-menu"
										isScheduled={isScheduled}
										anchorEl={filterButton.current}
										open={filterOpen}
										onClose={handleFilterClose}
										MenuListProps={{
											'aria-labelledby': 'lock-button',
											role: 'listbox',
										}}
									></FilterMenu>
								</Box>
							</StyledTableCell>

							<StyledTableCell
								className="icon"
								sx={{
									borderLeft: '0.5px solid #dddfe2',
								}}
							>
								<Button
									variant="contained"
									color="primary"
									disableElevation
									style={{ height: 42 }}
									onClick={handleFilterButtonsClick}
									ref={filterButtonDropDown}
									fullWidth
									className={classes.iconButton}
								>
									<ArrowDropDown sx={{ color: '#ffffff' }} />
								</Button>
								<FilterButtonsDropdown
									companyName={params.companyName}
									filterId={filterId}
									setFilterId={setFilterId}
									matchMode={matchMode}
									conditions={conditions}
									MenuListProps={{
										'aria-labelledby': 'lock-button',
										role: 'listbox',
									}}
									onClose={handleFilterButtonsClose}
									open={filterButtonsOpen}
									anchorEl={filterButtonDropDown.current}
								/>
							</StyledTableCell>
						</StyledTableRow>
					</TableHead>
				</Table>
				<div style={{ fontSize: '12px', color: '#0859a4', paddingTop: '5px', textAlign: 'center' }}>
					Total results: {searchState.list.total === undefined ? '...........' : `${searchState.list.total}`}
					<div>
						Page: {currentPage}/{`${Math.ceil(searchState.list.total / 25 || 1)}`}
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							outlineWidth: 0,
							outlineColor: 'black',
							outlineStyle: 'solid',
							width: '85%',
							padding: '5px',
							marginTop: 10,
							marginBottom: 10,
						}}
					>
						<Button
							color="primary"
							variant="contained"
							style={{ fontSize: '10px' }}
							onClick={handlePrevClick}
							disabled={isLoading || from <= 0}
						>
							Prev
						</Button>

						<Button
							color="primary"
							variant="contained"
							style={{ fontSize: '10px' }}
							onClick={handleNextClick}
							disabled={isLoading || searchState.list.total <= from + 25}
						>
							Next
						</Button>
					</div>
				</div>
				<CustomTableContainer
					ref={scrollRef}
					style={{
						height: uiState.calenderVisible ? 'calc(100vh - 116px)' : '100vh',
					}}
				>
					<Table sx={{ width: '100%' }}>
						{tab === 0 ? (
							<TableBody>
								{isLoading ? (
									<Loader />
								) : !unScheduledOrders.length ? (
									<NoItems />
								) : (
									unScheduledOrders.map((workOrder) => (
										<StyledTableRow
											style={{
												height: 80,
											}}
											key={workOrder.id}
										>
											<WorkOrderItemCell workOrder={workOrder} />
											<StyledTableCell align="right" className="icon">
												<IconButton
													onClick={() => {
														setSelectedOrder(workOrder);
														setOpenDialog(true);
													}}
													className={classes.editButton}
												>
													<IcEdit />
												</IconButton>
											</StyledTableCell>
										</StyledTableRow>
									))
								)}
							</TableBody>
						) : (
							<TableBody>
								{isLoading ? (
									<Loader />
								) : !scheduledOrders.length ? (
									<NoItems />
								) : (
									scheduledOrders.map((workOrder) => (
										<StyledTableRow style={{ height: 80 }} key={workOrder.id}>
											<WorkOrderItemCell workOrder={workOrder} isScheduled />
											<StyledTableCell align="right" className="icon">
												<IconButton
													onClick={() => {
														setSelectedOrder(workOrder);
														setOpenDialog(true);
													}}
													className={classes.editButton}
												>
													<IcEdit />
												</IconButton>
											</StyledTableCell>
										</StyledTableRow>
									))
								)}
							</TableBody>
						)}
					</Table>
					{ordersState.isPending && (
						<Box display="flex" py={2} alignItems={'center'} justifyContent="center">
							<CircularProgress />
						</Box>
					)}
				</CustomTableContainer>
			</AccordionDetails>
		</Accordion>
	);
};
