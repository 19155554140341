import moment from 'moment';
import Employee, { Hours } from '../models/Employee';

export interface IWorkingHours {
	amount: number[];
	from: number[];
	to: number[];
}

const getWorkingHours = (employee: Employee, day: string): IWorkingHours => {
	const keyFrom = `${day.toLowerCase()}From` as keyof Hours;
	const keyTo = `${day.toLowerCase()}To` as keyof Hours;

	const from = employee.hours![keyFrom];
	const to = employee.hours![keyTo];

	if (from === null || to === null) {
		return {
			amount: [0, 0],
			from: [0, 0],
			to: [0, 0],
		};
	}

	const nowFrom = moment.utc(`2000-01-01 ${from}:00`, 'YYYY-MM-DD HH:mm:ss');
	const nowTo = moment.utc(`2000-01-01 ${to}:00`, 'YYYY-MM-DD HH:mm:ss');

	let diff = Math.abs(nowFrom.diff(nowTo, 'minutes'));
	let hours = 0;
	let minutes = 0;
	while (diff >= 60) {
		hours++;

		diff -= 60;
	}
	if (diff > 0) {
		minutes = diff;
	}

	return {
		amount: [hours, minutes],
		from: [nowFrom.hours(), nowFrom.minutes()],
		to: [nowTo.hours(), nowTo.minutes()],
	};
};

export default getWorkingHours;
