import React, { useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AUser from '../../../models/Admin/AUser';
import { useAppDispatch } from '../../../store';
import { useAServersState } from '../../../store/selectors';
import aUsersAsyncActions from '../../../store/actions/aUsers.action';
import theme from '../../../theme';

const useStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
	container: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
});

export interface CreateUserModalProps {
	user?: AUser | null;
	open: boolean;
	onClose: () => void;
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({ user, open, onClose }) => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const aServersState = useAServersState();

	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [serverId, setServerId] = useState<string>('');
	const [username, setUsername] = useState<string>('');

	React.useEffect(() => {
		if (!open || !user) {
			return;
		}

		setFirstName(user.firstName ?? '');
		setLastName(user.lastName ?? '');
		setServerId(user.serverId);
		setUsername(user.username);
	}, [open, user]);

	return (
		<Dialog className={classes.root} open={open} fullWidth maxWidth="sm">
			<Box flex={1} padding={'2em'}>
				<Grid spacing={2} container>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'First Name'}
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Last Name'}
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Server</InputLabel>
							<Select
								classes={{ select: classes.container }}
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={serverId}
								label="Server"
								onChange={(e) => setServerId(e.target.value)}
							>
								{aServersState.list.map((element) => (
									<MenuItem key={element.id} value={element.id}>
										{element.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Username'}
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
				</Grid>
				<Box height={48} />
				<Grid spacing={2} container>
					<Grid item xs={6} sm={6} md={3}>
						<Button
							onClick={() => {
								if (user) {
									dispatch(
										aUsersAsyncActions.update({
											id: user.id,
											firstName,
											lastName,
											serverId,
											username,
										})
									);
								} else {
									dispatch(
										aUsersAsyncActions.store({
											firstName,
											lastName,
											serverId,
											username,
										})
									);
								}
								onClose();
							}}
							sx={{ height: '40px' }}
							fullWidth
							variant="contained"
						>
							Save
						</Button>
					</Grid>
					<Grid item xs={6} sm={6} md={3}>
						<Button sx={{ height: '40px' }} onClick={onClose} fullWidth variant="outlined">
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
};

export default CreateUserModal;
