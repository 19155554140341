import { createSlice } from '@reduxjs/toolkit';
import ErrorResponse from '../../network/responses/ErrorResponse';
import searchAsyncActions from '../actions/search.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';
import { CPA, SearchState, Timing } from '../types';

const initialState: SearchState = {
	applyFilters: false,
	isLoading: false,
	list: {
		documents: [],
		total: 0,
	},
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setIsLoading: (
			state,
			{
				payload,
			}: {
				payload: boolean;
			}
		) => {
			state.isLoading = payload;
		},
		clearFilters: (state) => {
			state.applyFilters = false;
			state.list = {
				documents: [],
				total: 0,
			};
			state.updatedAt = Timing.now();
		},
	},
	extraReducers: {
		[searchAsyncActions.search.fulfilled.type]: (state, action: CPA<string[]>) => {
			state.isLoading = false;
			state.applyFilters = true;
			// @ts-ignore
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[searchAsyncActions.advanced.fulfilled.type]: (state, action: CPA<string[]>) => {
			state.isLoading = false;
			state.applyFilters = true;
			// @ts-ignore
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[searchAsyncActions.search.rejected.type]: (state, action: CPA<ErrorResponse>) => {
			state.isLoading = false;
			postErrorRequest(state, action, initialState);
		},
		[searchAsyncActions.advanced.rejected.type]: (state, action: CPA<ErrorResponse>) => {
			state.isLoading = false;
			postErrorRequest(state, action, initialState);
		},
	},
});

export const searchActions = slice.actions;

export default slice.reducer;
