import { useLocalStorage } from '@cyboticx/hooks';
import { Container, CssBaseline } from '@mui/material';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';
import { useAppDispatch } from 'store';
import { removeAuthState } from 'store/slices/authentication.slice';

const Bootstrap = () => {
	const dispatch = useAppDispatch();
	const [accessToken] = useLocalStorage('accessToken', '');

	React.useEffect(() => {
		if (accessToken) return;
		dispatch(removeAuthState());
	}, [accessToken]);

	return (
		<>
			<Toaster />
			<CssBaseline />
			<Container
				maxWidth={false}
				disableGutters
				sx={{
					height: '100vh',
					overflow: 'auto',
				}}
			>
				<BrowserRouter>
					<Routes />
				</BrowserRouter>
			</Container>
		</>
	);
};

export default Bootstrap;
