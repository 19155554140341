import { Box, Dialog, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography } from 'lib/components';
import Employee from 'models/Employee';
import moment from 'moment';
import React, { useState } from 'react';
import { IcClock, IcDialogClose, IcDialogEdit } from 'res/icons';
import { useCalendarState } from '../../../../store/selectors';
import getAvailability from '../../../getAvailability';
import getAvailabilityText from '../../../getAvailabilityText';
import getUDFValue from '../../../getUDFValue';
import EditDialog from './EditDialog';

interface UserDialogProps {
	open: boolean;
	schedule: Employee;
	onClose: () => void;
}

const useUserDialogStyles = makeStyles({
	root: {},
	header: {
		padding: '15px 60px 15px 10px',
		minHeight: 30,
		position: 'relative',
		borderBottom: '0.5px solid #dddfe2',
		'& > .name': {
			marginBottom: 10,
			'& > span': {
				fontWeight: 600,
			},
		},
		'& > .details': {
			display: 'flex',
			alignItems: 'center',
			flexWrap: 'wrap',
			'& > .detail-item': {
				display: 'flex',
				alignItems: 'center',
				'& > p': {
					padding: '0 10px 0 5px',
				},
			},
		},
		'& > .icon-buttons': {
			position: 'absolute',
			top: 5,
			right: 5,
			padding: 0,
			display: 'flex',
			'& > .MuiIconButton-root': {
				height: 20,
				width: 20,
				padding: 0,
				marginRight: 5,
			},
		},
	},
	body: {
		paddingTop: 15,
		padding: '15px 10px',
		'& > .feature-item': {
			marginBottom: 5,
			'& > span': {
				color: '#0859a4',
				fontWeight: 600,
			},
		},
	},
});

const UserDialog = ({ onClose, open, schedule }: UserDialogProps) => {
	const classes = useUserDialogStyles();

	const [techLevel, setTechLevel] = React.useState<string>('');

	const [editOpen, setEditOpen] = useState(false);

	const calendarState = useCalendarState();

	const usedTime = React.useMemo<string>(() => {
		let minutes = 0;
		for (const date of calendarState.highlightedDates) {
			const momentDate = moment.utc(date, 'YYYY-MM-DD');

			const orders = schedule.orders.filter((element) =>
				element.startAt.includes(momentDate.format('YYYY-MM-DD'))
			);

			for (const order of orders) {
				const dates = order.startAt.split(',');

				const actualDates = dates.filter((element) => element.includes(momentDate.format('YYYY-MM-DD')));
				const startAt = moment.utc(actualDates[0]);
				const endAt = moment.utc(actualDates[1]);

				minutes += Math.abs(endAt.diff(startAt, 'minutes'));
			}
		}

		let hours = 0;
		while (minutes >= 60) {
			hours++;

			minutes -= 60;
		}

		return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
	}, [schedule, calendarState.highlightedDates]);

	React.useEffect(() => {
		if (!open) {
			return;
		}

		const value = getUDFValue(schedule.udfs, 'tech_level');
		if (value !== null && value !== '') {
			setTechLevel(value);
		}

		return () => {
			setTechLevel('');
		};
	}, [open]);

	return (
		<Dialog className={classes.root} open={open}>
			<Box className={classes.header}>
				<Typography variant="p" className="name">
					<span>{schedule.name}</span>
					{techLevel !== '' ? `: Tech ${techLevel} Level` : ''}
				</Typography>
				<Box className="details">
					<Box className="detail-item">
						{getAvailability(schedule.udfs)}
						<Typography variant="p">{getAvailabilityText(schedule.udfs)}</Typography>
					</Box>
					<Box className="detail-item">
						<IcClock />
						<Typography variant="p">{usedTime}</Typography>
					</Box>
				</Box>
				<Box className="icon-buttons">
					<IconButton onClick={() => setEditOpen(true)}>
						<IcDialogEdit />
					</IconButton>
					<IconButton onClick={onClose}>
						<IcDialogClose />
					</IconButton>
				</Box>
			</Box>
			<Box className={classes.body}>
				{schedule.udfs
					.filter((element) => element.value !== null && element.value !== '')
					.map((element) => (
						<Typography key={element.id} variant="p" className="feature-item">
							<span>{element.shape.label}:</span>{' '}
							{element.shape.dataType === 'BOOLEAN'
								? element.value === '1'
									? 'Yes'
									: 'No'
								: element.value}
						</Typography>
					))}
			</Box>
			<EditDialog schedule={schedule} open={editOpen} onClose={() => setEditOpen(false)} />
		</Dialog>
	);
};

export default UserDialog;
