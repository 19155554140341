import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import Bootstrap from './Bootstrap';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import theme from './theme';
import './index.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SocketManager from './lib/components/SocketManager';

interface CustomEventMap {
	customnumberevent: CustomEvent<number>;
}
declare global {
	interface Document {
		//adds definition to Document, but you can do the same with HTMLElement
		addEventListener<K extends keyof CustomEventMap>(
			type: K,
			listener: (this: Document, ev: CustomEventMap[K]) => void
		): void;
	}
}

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<SocketManager>
				<ThemeProvider theme={theme}>
					<StyledEngineProvider>
						<DndProvider backend={HTML5Backend}>
							<Bootstrap />
						</DndProvider>
					</StyledEngineProvider>
				</ThemeProvider>
			</SocketManager>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
