import { TableCell, tableCellClasses } from '@mui/material';
import { styled } from '@mui/system';
import Order from 'models/Order';
import moment from 'moment';
import React from 'react';
import { useDrag } from 'react-dnd';
import { useAppDispatch } from 'store';
import { useEmployeesState, useMultidragState, useOrdersState, useSchedulerState } from 'store/selectors';
import { DragItemTypes } from 'store/types';
import { calendarActions } from '../../../../store/slices/calendar.slice';
import { multidragActions } from '../../../../store/slices/multidrag.slice';
import { schedulerActions } from '../../../../store/slices/scheduler.slice';
import { uiActions } from '../../../../store/slices/ui.slice';
import WorkOrderItem from './WorkOrderItem';

const StyledTableCell = styled(TableCell)({
	padding: 0,
	minHeight: 80,
	'.tab-header &': {
		height: 40,
	},
	'.search-header &': {
		height: 30,
	},
	'&.icon': {
		width: '35px',
		maxWidth: '35px',
	},
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#f5f8fb',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
});

const WorkOrderItemCell: React.FC<{ workOrder: Order; isScheduled?: boolean }> = ({
	workOrder,
	isScheduled = false,
}) => {
	const dispatch = useAppDispatch();

	const schedulerState = useSchedulerState();
	const multidragState = useMultidragState();
	const ordersState = useOrdersState();
	const employeesState = useEmployeesState();

	const [{ isDragging, opacity }, drag] = useDrag({
		type: DragItemTypes.WORK_ORDER,
		item: { id: workOrder.id },
		canDrag: !isScheduled,
		collect: (monitor) => {
			return {
				isDragging: monitor.isDragging(),
				opacity: monitor.isDragging() ? 0.5 : 1,
			};
		},
	});

	React.useEffect(() => {
		dispatch(schedulerActions.setIsDragging(isDragging));
	}, [isDragging]);

	const handleClick = React.useCallback(
		(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			if (isScheduled) {
				const findEmployee = employeesState.list.find((element) =>
					element.orders.map((e) => e.orderId).includes(workOrder.id)
				);
				if (findEmployee) {
					const findOrder = findEmployee.orders.find((element) => element.orderId === workOrder.id);
					if (findOrder) {
						const startAtList = findOrder.startAt.split(',');
						const startAt = moment.utc(startAtList[0], 'YYYY-MM-DD HH:mm:ss');

						dispatch(calendarActions.setCurrent(startAt.format('YYYY-MM-DD')));
						dispatch(calendarActions.setStartDate(startAt.format('YYYY-MM-DD')));
						dispatch(calendarActions.setEndDate(startAt.format('YYYY-MM-DD')));
						dispatch(
							calendarActions.setHighlightedDates({
								startDate: startAt.format('YYYY-MM-DD'),
								endDate: startAt.format('YYYY-MM-DD'),
							})
						);

						const hour = startAt.hour();
						const minute = startAt.minute();

						const posX =
							hour * schedulerState.schedulerResolution.oneHourWidth +
							minute * schedulerState.schedulerResolution.oneMinuteWidth;

						dispatch(uiActions.pulseOrderId(findOrder.orderId));
						dispatch(schedulerActions.scrollSchedulerTo(posX));
					}
				}

				return;
			}

			const workOrderIndex = ordersState.list
				.filter((element) => !element.isScheduled)
				.findIndex((item) => parseInt(item.id) === parseInt(workOrder.id));

			if (ev.shiftKey) {
				if (multidragState.lastSelectedIndex !== -1) {
					let ids: string[] = [];
					if (multidragState.lastSelectedIndex > workOrderIndex) {
						ids = ordersState.list
							.filter((element) => !element.isScheduled)
							.slice(workOrderIndex, multidragState.lastSelectedIndex)
							.map((element) => element.id);
					} else {
						ids = ordersState.list
							.filter((element) => !element.isScheduled)
							.slice(multidragState.lastSelectedIndex, workOrderIndex + 1)
							.map((element) => element.id);
					}

					dispatch(
						multidragActions.toggleOrders({
							ids,
							index: workOrderIndex,
							isShiftKey: true,
						})
					);
				} else {
					dispatch(
						multidragActions.toggleOrders({
							ids: [workOrder.id],
							index: workOrderIndex,
							isShiftKey: true,
						})
					);
				}
			} else if (ev.metaKey || ev.ctrlKey) {
				dispatch(
					multidragActions.toggleOrders({
						ids: [workOrder.id],
						index: workOrderIndex,
						isShiftKey: false,
					})
				);
			} else {
				dispatch(multidragActions.resetSelectedOrders());
				dispatch(
					multidragActions.toggleOrders({
						ids: [workOrder.id],
						index: workOrderIndex,
						isShiftKey: false,
					})
				);
			}
		},
		[isScheduled, ordersState.list, ordersState.updatedAt, multidragState.lastSelectedIndex]
	);
	// const handleMouseDown = React.useCallback(
	// 	(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
	// 		if (isScheduled) {
	// 			return;
	// 		}

	// 		const workOrderIndex = ordersState.list.findIndex((item) => parseInt(item.id) === parseInt(workOrder.id));
	// 		dispatch(setLastSelected(workOrderIndex));

	// 		if (ev.shiftKey) {
	// 			if (multidragState.lastSelectedIndex !== -1) {
	// 				let workOrders: Order[] = [];
	// 				if (multidragState.lastSelectedIndex > workOrderIndex) {
	// 					workOrders = [...ordersState.list.slice(workOrderIndex, multidragState.lastSelectedIndex)];
	// 				} else {
	// 					workOrders = [...ordersState.list.slice(multidragState.lastSelectedIndex, workOrderIndex + 1)];
	// 				}
	// 				dispatch(concatOrder(workOrders));
	// 			} else {
	// 				dispatch(appendOrder(workOrder));
	// 			}
	// 		} else if (ev.metaKey || ev.ctrlKey) {
	// 			dispatch(toggleSelectedOrder(workOrder));
	// 		} else {
	// 			dispatch(appendOrder(workOrder));
	// 		}
	// 	},
	// 	[isScheduled, ordersState.list, ordersState.updatedAt]
	// );
	//
	return (
		<StyledTableCell style={{ opacity }} ref={drag} component="th" colSpan={2}>
			<div
				style={{
					transform: workOrder.isIncompleted ? 'scale(1)' : undefined,
					animation: workOrder.isIncompleted ? 'pulse 2s infinite' : undefined,
					border: workOrder.isIncompleted ? 'solid 1px #FF0000' : undefined,
					// borderRightColor: 'transparent',
				}}
			>
				<WorkOrderItem
					sx={{
						backgroundColor:
							workOrder.estimatedHours > workOrder.previousEstimatedHours ? '#FF886E' : undefined,
						border: multidragState.list.find((element) => element === workOrder.id)
							? '1px solid #0859a4'
							: '1px solid transparent',
					}}
					onClick={handleClick}
					// onMouseDown={handleMouseDown}
					workOrder={workOrder}
				/>
			</div>
		</StyledTableCell>
	);
};

export default WorkOrderItemCell;
