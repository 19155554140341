import { Box, Button, ButtonProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { IcAddress, IcEstimatedTime, IcWorkOrder } from 'res/icons';
import Order from '../../../../models/Order';

interface WorkOrderItemProps {
	workOrder: Order;
}

const WorkOrderItem = styled(({ workOrder, ...props }: ButtonProps & WorkOrderItemProps) => {
	return (
		<Button style={{ height: 80 }} disableRipple disableFocusRipple disableTouchRipple fullWidth {...props}>
			<Box>
				<Box alignItems={'center'} className="desc">
					<IcWorkOrder style={{ color: workOrder.phase ? workOrder.phase.color : '#0859A4' }} />
					&nbsp;&nbsp;
					<Typography sx={{ textTransform: 'none' }} variant="body2">
						WO: <span>{workOrder.orderNo}</span>
					</Typography>
				</Box>
				<Box alignItems={'center'} className="desc">
					<IcAddress />
					&nbsp;&nbsp;
					<Typography sx={{ textTransform: 'none' }} variant="body2">
						<span>{workOrder.address ? workOrder.address.line1 : ''}</span>
					</Typography>
				</Box>
				<Box alignItems={'center'} className="desc">
					<IcEstimatedTime />
					&nbsp;&nbsp;
					<Typography sx={{ textTransform: 'none' }} variant="body2">
						ETA:{' '}
						<span>
							{workOrder.estimatedHours} Hour{workOrder.estimatedHours > 1 ? 's' : ''}
						</span>
					</Typography>
				</Box>
			</Box>
		</Button>
	);
})(() => ({
	flexWrap: 'wrap',
	height: '100%',
	borderRadius: 0,
	userSelect: 'none',
	justifyContent: 'flex-start',
	padding: '10px 20px',
	color: '#000000',
	backgroundColor: '#f5f8fb',
	'&:hover': {
		backgroundColor: '#f5f8fb',
		transition: 'all ease .3s',
		userSelect: 'none',
		cursor: 'pointer',
	},
	'& .desc': {
		height: 20,
		// padding: '2px 0',
		display: 'flex',
		'& > svg': {
			width: 10,
			minWidth: 10,
		},
		'& > p': {
			fontSize: '11px',
			'& > span': {
				color: '#0859a4',
			},
		},
	},
}));

export default WorkOrderItem;
