import { createSlice } from '@reduxjs/toolkit';
import { MultiDragState, PA } from 'store/types';

const initialState: MultiDragState = {
	list: [],
	lastSelectedIndex: -1,
};

const multidragSlice = createSlice({
	name: 'multidrag',
	initialState,
	reducers: {
		toggleOrders: (
			state,
			{
				payload,
			}: PA<{
				ids: string[];
				index: number;
				isShiftKey: boolean;
			}>
		) => {
			for (const id of payload.ids) {
				const findIndex = state.list.findIndex((element) => element === id);
				if (findIndex === -1) {
					state.list.push(id);
				} else if (!payload.isShiftKey) {
					state.list.splice(findIndex, 1);
				}
			}

			state.lastSelectedIndex = payload.index;
		},
		resetSelectedOrders: (state) => {
			state.list = [];
			state.lastSelectedIndex = -1;
		},
	},
});

export const multidragActions = multidragSlice.actions;

export default multidragSlice.reducer;
