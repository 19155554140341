import { Box, Button, Dialog, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
	open: boolean;
	onclose: () => void;
}

const useWorkDialogStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
});

const LogoutModal: React.FC<Props> = ({ open, onclose }) => {
	const classes = useWorkDialogStyles();
	const { push } = useHistory();

	const logout = React.useCallback(() => {
		push('/logout');
	}, []);

	return (
		<Dialog className={classes.root} open={open} fullWidth maxWidth="xs">
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={120}>
				<Typography variant="h6">Are you sure you want to logout?</Typography>
				<Box
					columnGap="3em"
					display="flex"
					paddingTop="1em"
					paddingBottom="1em"
					paddingLeft="3em"
					paddingRight="3em"
				>
					<Button onClick={onclose} variant="outlined">
						Close
					</Button>
					<Button onClick={logout} color="primary" variant="contained">
						Logout
					</Button>
				</Box>
			</Box>
		</Dialog>
	);
};

export default LogoutModal;
