import { Box, Button, CircularProgress, Dialog, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import images from '../../../res/images';
import ordersAsyncActions from '../../../store/actions/orders.action';
import RequestManager from '../../../store/request-manager';
import { useRequestState } from '../../../store/selectors';

interface Props {
	orderId: string;
	open: boolean;
	onClose: () => void;
	refreshFn?: () => void;
}

const useWorkDialogStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
});

const SendToSpireConfirmModal: React.FC<Props> = (props: Props) => {
	const dispatch = useDispatch();

	const classes = useWorkDialogStyles();

	const [isLoading, setIsLoading] = React.useState(false);

	const requestState = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(requestState.updatedAt);

	React.useEffect(() => {
		if (requestUpdatedAt === requestState.updatedAt) {
			return;
		}
		const RM = new RequestManager(requestState, dispatch);

		if (RM.isPending(ordersAsyncActions.override.typePrefix)) {
			setIsLoading(true);
		}

		if (RM.isFinished(ordersAsyncActions.override.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(ordersAsyncActions.override.typePrefix)) {
			RM.consume(ordersAsyncActions.override.typePrefix);

			toast.success('Successfully overriden Order history.');

			props.onClose();
		}
	}, [requestUpdatedAt, requestState.updatedAt]);

	return (
		<Dialog className={classes.root} open={props.open} fullWidth maxWidth="xs">
			<Box paddingTop="3em" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
				<img width="100%" src={images.default.ilNotaccepted} />
				<Box
					rowGap="1em"
					paddingY="2em"
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					paddingX="2em"
				>
					<Typography
						sx={{ fontSize: '1rem', color: '#0859a4', fontWeight: 'bold' }}
						paddingX="3em"
						textAlign="center"
					>
						Are you sure you want to send order history to Spire? This will override any data hold in Spire
						Database related to this Order.
					</Typography>
					<Box display="flex" alignItems="center" justifyContent="center">
						<Button
							disabled={isLoading}
							onClick={() => dispatch(ordersAsyncActions.override(props.orderId))}
							size="small"
							variant="contained"
						>
							{isLoading && (
								<>
									<CircularProgress size={'16px'} />
									<Box width={16} />
								</>
							)}
							Proceed
						</Button>
						<Box width={16} />
						<Button disabled={isLoading} onClick={props.onClose} size="small" variant="outlined">
							Cancel
						</Button>
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
};

export default SendToSpireConfirmModal;
