import { Box, Button, Dialog, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import AServer from '../../../models/Admin/AServer';
import { useAppDispatch } from '../../../store';
import aServersAsyncActions from '../../../store/actions/aServers.action';
const useStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
});
export interface Props {
	server?: AServer | null;
	open: boolean;
	onClose: () => void;
}
const CreateServerModal: React.FC<Props> = ({ server, open, onClose }) => {
	const classes = useStyles();

	const dispatch = useAppDispatch();

	const [code, setCode] = React.useState<string>('');
	const [name, setName] = React.useState<string>('');
	const [host, setHost] = React.useState<string>('');

	React.useEffect(() => {
		if (!open || !server) {
			return;
		}

		setCode(server.code ?? '');
		setName(server.name ?? '');
		setHost(server.host ?? '');
	}, [open]);

	return (
		<Dialog className={classes.root} open={open} fullWidth maxWidth="sm">
			<Box flex={1} padding={'2em'}>
				<Grid spacing={2} container>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Code'}
							value={code}
							onChange={(e) => setCode(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Name'}
							value={name}
							onChange={(e) => setName(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Host'}
							value={host}
							onChange={(e) => setHost(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
				</Grid>
				<Box height={48} />
				<Grid spacing={2} container>
					<Grid item xs={6} sm={6} md={3}>
						<Button
							onClick={() => {
								if (server) {
									dispatch(
										aServersAsyncActions.update({
											id: server.id,
											code,
											name,
											host,
										})
									);
								} else {
									dispatch(
										aServersAsyncActions.store({
											code,
											name,
											host,
										})
									);
								}
								onClose();
							}}
							sx={{ height: '40px' }}
							fullWidth
							variant="contained"
						>
							Save
						</Button>
					</Grid>
					<Grid item xs={6} sm={6} md={3}>
						<Button sx={{ height: '40px' }} onClick={onClose} fullWidth variant="outlined">
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
};

export default CreateServerModal;
