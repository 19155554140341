import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import OkResponse from 'network/responses/OkResponse';
import API from '../../lib/utils/API';
import { OrderPhase } from '../../models/Order';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('orderPhases/index', async (companyName: string, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, AxiosResponse<OrderPhase[]>>(`/phases/${companyName}`);

		thunkApi.dispatch(requestActions.beforeFulfilled(index.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(index.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { companyName }, error });
	}
});

const setDefault = createAsyncThunk(
	'orderPhases/set-default',
	async (payload: { companyName: string; id: string }, thunkApi) => {
		thunkApi.dispatch(requestActions.started(setDefault.typePrefix));
		try {
			const response = await API.post<Pick<typeof payload, 'id'>, AxiosResponse<OrderPhase>>(
				`/phases/${payload.companyName}/set-default`,
				{
					id: payload.id,
				}
			);

			thunkApi.dispatch(requestActions.beforeFulfilled(setDefault.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(setDefault.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const freightVerifySync = createAsyncThunk(
	'orderPhases/freight-verify-sync',
	async (payload: { companyName: string }, thunkApi) => {
		thunkApi.dispatch(requestActions.started(freightVerifySync.typePrefix));
		try {
			const response = await API.get<typeof payload, AxiosResponse<OkResponse>>(
				`/phases/${payload.companyName}/freight-verify-sync`
			);

			thunkApi.dispatch(requestActions.beforeFulfilled(freightVerifySync.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(freightVerifySync.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const sync = createAsyncThunk('orderPhases/sync', async (companyName: string, thunkApi) => {
	thunkApi.dispatch(requestActions.started(sync.typePrefix));
	try {
		const response = await API.get<any, OrderPhase[]>(`/phases/${companyName}/sync`);

		thunkApi.dispatch(requestActions.beforeFulfilled(sync.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(sync.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { companyName }, error });
	}
});

const update = createAsyncThunk('orderPhases/update', async (payload: { id: string; color: string }, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<any, OrderPhase>(`/phases/${payload.id}`, {
			color: payload.color,
		});

		thunkApi.dispatch(requestActions.beforeFulfilled(update.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(update.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { id: payload.id }, error });
	}
});

const orderPhasesAsyncActions = {
	index,
	setDefault,
	freightVerifySync,
	sync,
	update,
};

export default orderPhasesAsyncActions;
