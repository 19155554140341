import { createSlice } from '@reduxjs/toolkit';
import Filter from '../../models/Filter';
import ErrorResponse from '../../network/responses/ErrorResponse';
import OkResponse from '../../network/responses/OkResponse';
import authenticationAsyncActions from '../actions/authentication.action';
import filtersAsyncActions from '../actions/filters.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';
import { CPA, FiltersState, Timing } from '../types';

const initialState: FiltersState = {
	isInitialized: false,
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		clear: () => initialState,
	},
	extraReducers: {
		[filtersAsyncActions.index.fulfilled.type]: (state, action: CPA<Filter[]>) => {
			state.isInitialized = true;
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[filtersAsyncActions.store.fulfilled.type]: (state, action: CPA<Filter>) => {
			state.list.push(action.payload);
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[filtersAsyncActions.update.fulfilled.type]: (state, action: CPA<Filter>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[filtersAsyncActions.setDefault.fulfilled.type]: (state, action: CPA<Filter>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[filtersAsyncActions.destroy.fulfilled.type]: (state, action: CPA<string>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[filtersAsyncActions.destroyDefault.fulfilled.type]: (state, action: CPA<OkResponse>) => {
			const findIndex = state.list.findIndex((element) => element.isDefault);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[filtersAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[filtersAsyncActions.store.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[filtersAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[filtersAsyncActions.setDefault.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[filtersAsyncActions.destroy.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[filtersAsyncActions.destroyDefault.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signIn.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
	},
});

export const filtersActions = slice.actions;

export default slice.reducer;
