import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import AdminPortalScreen from 'screens/AdminPortal';
import { DashboardScreen, SelectCompanScreen, SigninScreen } from 'screens/AuthBoardScreen';
import ScheduleBoardScreen from 'screens/ScheduleBoardScreen';
import ScheduleBoardSettings from 'screens/ScheduleBoardScreen/Settings';
import { useAuthenticationState } from 'store/selectors';
import Authenticated from './authenticated';
import AdminRoute from './admin';
import Public from './public';
const Routes = () => {
	const { isAuthenticated, isAdministrator } = useAuthenticationState();

	return (
		<>
			<Switch>
				<Public exact path={['/', '/logout']} component={SigninScreen} />
				<Authenticated
					exact
					path="/company/:companyName/scheduleboard/settings"
					component={ScheduleBoardSettings}
				/>
				<Authenticated exact path="/selectcompany" component={SelectCompanScreen} />
				<Authenticated exact path="/company/:companyName/dashboard" component={DashboardScreen} />
				<Authenticated exact path="/company/:companyName/scheduleboard" component={ScheduleBoardScreen} />
				<AdminRoute exact path="/administrator/dashboard" component={AdminPortalScreen} />

				{!isAuthenticated && (
					<Authenticated path="*">
						<Redirect to="/" />
					</Authenticated>
				)}

				{isAuthenticated && !isAdministrator && (
					<Authenticated path="*">
						<Redirect to="/selectcompany" />
					</Authenticated>
				)}

				{isAuthenticated && isAdministrator && (
					<AdminRoute path="*">
						<Redirect to="/administrator/dashboard" />
					</AdminRoute>
				)}
			</Switch>
		</>
	);
};

export default Routes;
