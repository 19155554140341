import { Close } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { makeStyles } from '@mui/styles';
import React from 'react';
import FilterCondition from '../../../../models/FilterCondition';
import FilterMatchMode from '../../../../models/FilterMatchMode';
import { useAppDispatch } from '../../../../store';
import { useFiltersState } from '../../../../store/selectors';
import filtersAsyncActions from '../../../../store/actions/filters.action';

const useStyles = makeStyles({
	root: {
		'& .MuiPaper-root': { padding: 0, borderRadius: 0, backgroundColor: '#0859a4' },
		'& .filter-item': {
			color: '#0859a4',
			'&.active': {
				fontWeight: 600,
			},
		},
	},
	buttons: {
		color: '#ffffff !important',
		borderBottom: '1px solid #ffffff',
		textAlign: 'left',
		display: 'block',
		padding: '0.5em',
		paddingLeft: '0.8em 1em',
		fontSize: '.8rem',
		width: '100%',
		textTransform: 'capitalize',
		borderRadius: 0,
	},
	buttonDisabled: {
		color: '#cccccc !important',
	},
});

interface Props {
	companyName: string;
	filterId: string | undefined;
	matchMode: FilterMatchMode;
	conditions: FilterCondition[];
	setFilterId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const FilterButtonsDropdown = ({
	companyName,
	filterId,
	matchMode,
	conditions,
	setFilterId,
	...props
}: Props & MenuProps) => {
	const classes = useStyles();

	const dispatch = useAppDispatch();

	const filtersState = useFiltersState();

	const handleSaveAsDefault = React.useCallback(() => {
		if (filterId !== undefined) {
			dispatch(filtersAsyncActions.setDefault({ companyName, filterId }));
		} else {
			dispatch(
				filtersAsyncActions.store({
					companyName,
					isDefault: true,
					matchMode,
					conditions,
				})
			);
		}
	}, [filterId, matchMode, conditions]);

	const handleRemoveDefault = React.useCallback(() => {
		dispatch(filtersAsyncActions.destroyDefault({ companyName }));
	}, [filterId, matchMode, conditions]);

	const handleSave = React.useCallback(() => {
		if (filterId !== undefined) {
			dispatch(filtersAsyncActions.update({ companyName, isDefault: false, filterId, matchMode, conditions }));
		} else {
			dispatch(
				filtersAsyncActions.store({
					companyName,
					isDefault: false,
					matchMode,
					conditions,
				})
			);
		}
	}, [filterId, matchMode, conditions]);

	const handleDelete = React.useCallback(
		(filterId: string) => {
			dispatch(filtersAsyncActions.destroy({ filterId }));
		},
		[matchMode, conditions]
	);

	return (
		<Menu
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			{...props}
			className={classes.root}
		>
			<Box sx={{ width: '300px' }}>
				<Button
					onClick={handleSaveAsDefault}
					className={classes.buttons}
					classes={{ disabled: classes.buttonDisabled }}
				>
					Save as default
				</Button>
				<Button
					onClick={handleRemoveDefault}
					className={classes.buttons}
					classes={{ disabled: classes.buttonDisabled }}
				>
					Remove default
				</Button>
				<Button onClick={handleSave} className={classes.buttons} classes={{ disabled: classes.buttonDisabled }}>
					Save filter
				</Button>
				{filtersState.list.map((element, index) => (
					<Box key={element.id} display={'flex'} flexDirection={'row'}>
						<Button
							style={{ display: 'flex', flexBasis: '90%', flexShrink: 0 }}
							onClick={() => setFilterId(element.id)}
							className={classes.buttons}
							classes={{ disabled: classes.buttonDisabled }}
						>
							Filter #{index}
						</Button>
						<Button
							style={{
								display: 'flex',
								flexBasis: '10%',
								borderBottom: '1px solid #ffffff',
								borderRadius: 0,
								minWidth: 'initial',
							}}
							onClick={() => handleDelete(element.id)}
							classes={{ disabled: classes.buttonDisabled }}
						>
							<Close sx={{ color: '#FA2E2E', fontSize: '1rem' }} />
						</Button>
					</Box>
				))}
			</Box>
		</Menu>
	);
};

export default FilterButtonsDropdown;
