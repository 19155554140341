import { createSlice } from '@reduxjs/toolkit';
import ErrorResponse from 'network/responses/ErrorResponse';
import { ASyncUsersState, CPA, Timing } from 'store/types';
import ASyncUser from '../../models/Admin/ASyncUser';
import CheckPermissionsResponse from '../../network/responses/CheckPermissionsResponse';
import aSyncUsersAsyncActions from '../actions/aSyncUsers.action';
import authenticationAsyncActions from '../actions/authentication.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: ASyncUsersState = {
	lastCreatedId: null,
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'aSyncUsers',
	initialState,
	reducers: {},
	extraReducers: {
		[aSyncUsersAsyncActions.index.fulfilled.type]: (state, action: CPA<ASyncUser[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[aSyncUsersAsyncActions.store.fulfilled.type]: (state, action: CPA<ASyncUser>) => {
			state.lastCreatedId = action.payload.id;
			state.list.push(action.payload);
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[aSyncUsersAsyncActions.update.fulfilled.type]: (state, action: CPA<ASyncUser>) => {
			state.lastCreatedId = null;
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[aSyncUsersAsyncActions.block.fulfilled.type]: (state, action: CPA<ASyncUser>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[aSyncUsersAsyncActions.destroy.fulfilled.type]: (state, action: CPA<Pick<ASyncUser, 'id'>>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[aSyncUsersAsyncActions.checkPermissions.fulfilled.type]: (state, action: CPA<CheckPermissionsResponse>) => {
			postRequest(action);
		},
		[aSyncUsersAsyncActions.sync.fulfilled.type]: (state, action: CPA<CheckPermissionsResponse>) => {
			postRequest(action);
		},
		[aSyncUsersAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aSyncUsersAsyncActions.store.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aSyncUsersAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aSyncUsersAsyncActions.block.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aSyncUsersAsyncActions.destroy.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aSyncUsersAsyncActions.checkPermissions.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aSyncUsersAsyncActions.sync.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
	},
});

export const aSyncUsersActions = slice.actions;

export default slice.reducer;
