import React from 'react';
import R from 'res';
import { LightVariantImage, DarkVariantImage, DefaultVariant, ImageSource } from 'types';
import classes from './index.module.scss';

type Props = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'srcSet'> & {
	className?: string;
	src: ImageSource;
};

interface AvatarProps extends Props {
	size: number | string;
}

export const Avatar = ({ size, ...props }: AvatarProps) => {
	return (
		<Image
			style={{
				width: size,
				height: size,
				borderRadius: '50%',
				verticalAlign: 'middle',
			}}
			{...props}
		/>
	);
};

const Image = ({ src, className, ...props }: Props) => {
	let image = '';

	if (!src.startsWith('http') && !src.startsWith('data')) {
		const [variant, filename] = src.split('.');
		if (variant === 'dark') {
			image = R.images.dark[filename as DarkVariantImage];
		} else if (variant === 'light') {
			image = R.images.light[filename as LightVariantImage];
		} else {
			image = R.images.default[src as DefaultVariant];
		}

		return <img src={image} alt={src} className={className ?? classes['image']} {...props} />;
	}

	return <img src={src} alt={src} className={className ?? classes['image']} {...props} />;
};

export default Image;
