import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useAAdminsState } from 'store/selectors';
import AAdmin from '../../../models/Admin/AAdmin';
import { useAppDispatch } from '../../../store';
import aAdminsAsyncActions from '../../../store/actions/aAdmins.action';
import CreateAdminModal from './CreateAdminModal';

const AdminsTab = () => {
	const dispatch = useAppDispatch();

	const users = useAAdminsState();

	const [openModal, setOpenModal] = React.useState<boolean>(false);
	const [user, setUser] = React.useState<AAdmin | null>(null);

	const closeDialog = React.useCallback(() => {
		setOpenModal(false);
	}, []);
	const openDialog = React.useCallback(() => {
		setOpenModal(true);
	}, []);

	return (
		<>
			<CreateAdminModal user={user} onClose={closeDialog} open={openModal} />

			<Box>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="Users table">
						<TableHead>
							<TableRow>
								<TableCell sx={{ color: '#0859a4' }} component="h6">
									FULL NAME
								</TableCell>
								<TableCell sx={{ color: '#0859a4' }} component="h6" color="primary" align="left">
									USERNAME
								</TableCell>
								<TableCell
									sx={{ color: '#0859a4', paddingLeft: 3, paddingRight: 3 }}
									component="h6"
									align="right"
								>
									ACTION
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users.list.map((user, index) => (
								<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell sx={{ fontWeight: 'bold' }}>
										{user.firstName} {user.lastName}
									</TableCell>
									<TableCell sx={{ fontWeight: 'bold' }}> {user.username} </TableCell>
									<TableCell sx={{ width: 350 }} align="right">
										{/* <Button>LOGIN</Button> */}
										<Button
											onClick={() => {
												setUser(user);
												openDialog();
											}}
										>
											EDIT
										</Button>
										<Button
											onClick={() => dispatch(aAdminsAsyncActions.destroy({ id: user.id }))}
											color="error"
										>
											DELETE
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default AdminsTab;
