import { createSlice } from '@reduxjs/toolkit';
import { CPA, InventoryItemsState, Timing } from 'store/types';
import InventoryItem from '../../models/InventoryItem';
import ErrorResponse from '../../network/responses/ErrorResponse';
import PaginatedResponse from '../../network/responses/PaginatedResponse';
import authenticationAsyncActions from '../actions/authentication.action';
import inventoryItemsAsyncActions from '../actions/inventoryItems.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: InventoryItemsState = {
	hasMorePages: true,
	isPending: false,
	list: [],
	search: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'inventoryItems',
	initialState,
	reducers: {
		clear: () => initialState,
	},
	extraReducers: {
		[inventoryItemsAsyncActions.index.pending.type]: (state) => {
			state.isPending = true;
		},
		[inventoryItemsAsyncActions.indexByIds.pending.type]: (state) => {
			state.isPending = true;
		},
		[inventoryItemsAsyncActions.search.pending.type]: (state) => {
			state.isPending = true;
		},
		[inventoryItemsAsyncActions.index.fulfilled.type]: (state, action: CPA<PaginatedResponse<InventoryItem>>) => {
			state.hasMorePages = action.payload.hasMorePages;
			state.isPending = false;
			for (const order of action.payload.data) {
				const findIndex = state.list.findIndex((element) => element.id === order.id);
				if (findIndex === -1) {
					state.list.push(order);
				} else {
					state.list.splice(findIndex, 1, order);
				}
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[inventoryItemsAsyncActions.indexByIds.fulfilled.type]: (state, action: CPA<InventoryItem[]>) => {
			state.isPending = false;
			for (const order of action.payload) {
				const findIndex = state.list.findIndex((element) => element.id === order.id);
				if (findIndex === -1) {
					state.list.push(order);
				} else {
					state.list.splice(findIndex, 1, order);
				}
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[inventoryItemsAsyncActions.search.fulfilled.type]: (state, action: CPA<string[]>) => {
			state.search = action.payload;
			state.isPending = false;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[inventoryItemsAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) => {
			state.isPending = false;

			return postErrorRequest(state, action, initialState);
		},
		[inventoryItemsAsyncActions.indexByIds.rejected.type]: (state, action: CPA<ErrorResponse>) => {
			state.isPending = false;

			return postErrorRequest(state, action, initialState);
		},
		[inventoryItemsAsyncActions.search.rejected.type]: (state, action: CPA<ErrorResponse>) => {
			state.isPending = false;

			return postErrorRequest(state, action, initialState);
		},
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
	},
});

export const inventoryItemsActions = slice.actions;

export default slice.reducer;
