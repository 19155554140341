import React from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { useAuthenticationState } from 'store/selectors';
import { Timing } from '../store/types';

const AdminRoute = ({ component, ...rest }: RouteProps) => {
	const { isAuthenticated, isAdministrator, expiryAt } = useAuthenticationState();
	const { pathname } = useLocation();

	return isAuthenticated && !Timing.isExpired(expiryAt) ? (
		isAdministrator ? (
			<Route {...rest} component={component} />
		) : (
			<Redirect to="/selectcompany" />
		)
	) : (
		<Redirect to={`/?redirectTo=${pathname}`} />
	);
};

export default AdminRoute;
