import moment from 'moment';

export const getHighlightedDates = (startDate: string, endDate: string): string[] => {
	const dates: string[] = [];

	const startDateMoment = moment.utc(startDate, 'YYYY-MM-DD');
	const endDateMoment = moment.utc(endDate, 'YYYY-MM-DD');

	while (startDateMoment.isSameOrBefore(endDateMoment, 'day')) {
		dates.push(startDateMoment.format('YYYY-MM-DD'));

		startDateMoment.add(1, 'day');
	}

	return dates;
};
