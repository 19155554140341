import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Breadcrumbs = styled((props: BreadcrumbsProps) => {
	return <MuiBreadcrumbs separator={<Typography>&gt;</Typography>} {...props} />;
})((props) => ({
	padding: '10px 60px',
	[props.theme.breakpoints.down('sm')]: {
		padding: 10,
	},
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	'& .nav-item': {
		display: 'flex',
		alignItem: 'center',
		padding: '5px 0',
		fontSize: 14,
		fontWeight: 400,
		textTransform: 'uppercase',
		color: '#000000',
		'&.active': {
			fontWeight: 600,
			color: '#0859a4',
		},
	},
}));
